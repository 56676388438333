import { useNavigate } from "react-router-dom";
import LandingPageContainerB from "./LandingPageContainerB";
import { LandingPagePathB } from ".";
import { CurrentUser } from "../../controllers/CurrentUser";
import DietGraphSVG from "../dietprofile/DietGraphSVG";
import { useEffect, useRef } from "react";

const LandingPageGraphB = () => {
  const navigate = useNavigate();

  const scrollRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setTimeout(() => {
      scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 500);
  }, []);

  const onContinue = () => {
    setTimeout(() => {
      navigate(LandingPagePathB.Figure, { replace: true });
    }, 500);
  };

  return (
    <LandingPageContainerB>
      <div className="dpq-wrapper">
        <div className="dpq-question d-block" id="bcm-graph" data-progress="graph">
          <h3 className="text-center salmon">
            <strong ref={scrollRef}>Ok, vous voulez perdre {CurrentUser.targetWeightloss}&nbsp;kilos </strong>
          </h3>
          <h4 className="graphInfo">Sans chambouler vos habitudes... ni reprendre le poids perdu. </h4>
          <h4 className="graphInfo">Nous sommes justement là pour ça 😊</h4>
          {/* <div className="dpq-graph text-center">
            <img src="/dietprofile-images/bcm-dp-graph.jpg" alt="" className="img-fluid mx-auto" />
          </div> */}
          {/* <hr />
          <div className="dpq-graph text-center">
            <DietGraph
              initialWeight={CurrentUser.profile.initialWeight || 80}
              targetWeight={CurrentUser.profile.targetWeight || 70}
            />
          </div>
          <hr /> */}
          <div className="dpq-graph text-center">
            <DietGraphSVG
              initialWeight={CurrentUser.profile.weight || 80}
              targetWeight={CurrentUser.profile.targetWeight || 70}
            />
          </div>

          <p className="text-center">Voyons maintenant le meilleur moyen d’y arriver… et en combien de temps !</p>
          <div className="text-center">
            <button type="button" className="cta-salmon" onClick={onContinue}>
              Continuer
            </button>
          </div>
        </div>
      </div>
    </LandingPageContainerB>
  );
};

export default LandingPageGraphB;
