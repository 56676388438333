export enum DietProfilePath {
    Start = '/cookingminceur',
    SelectIdeal = '/cookingminceur/ideal',
    UserDetails = '/cookingminceur/details',
    DietGraph =  '/cookingminceur/graph',
    Figure = '/cookingminceur/figure',
    Exces = '/cookingminceur/exces',
    Activity = '/cookingminceur/activite',
    Quiz = '/cookingminceur/quiz',
    Connaissez = '/cookingminceur/connaissez',
    Equipement = '/cookingminceur/equipement',
    Preparer = '/cookingminceur/preparer',
    Poids = '/cookingminceur/poids',
    LoaderForm = '/cookingminceur/loading',
    Results = '/cookingminceur/results',
}