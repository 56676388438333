import { useNavigate } from "react-router-dom";
import LandingPageContainer from "./LandingPageContainer";
import { LandingPagePath } from ".";
import { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { CurrentUser } from "../../controllers/CurrentUser";

const LandingPageEquipement = () => {
  const [selectedItems, setSelectedItems] = useState<number[]>(CurrentUser.answers.q8 ?? []);
  const ref = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }, 500);
  }, [selectedItems]);

  const onSelect = (value: number) => {
    if (selectedItems.includes(value)) {
      setSelectedItems(selectedItems.filter((i) => i !== value));
    } else {
      setSelectedItems((items) => [...items, value]);
    }
  };

  const onContinue = () => {
    CurrentUser.answers.q8 = selectedItems;

    setTimeout(() => {
      navigate(LandingPagePath.Preparer);
    }, 500);
  };

  return (
    <LandingPageContainer>
      <div className="dpq-wrapper">
        <div className="dpq-question d-block" data-progress="8">
          <h3 className="text-center  emerald">
            <strong>Votre équipement en cuisine comprend :</strong>
          </h3>
          <div className="multipleChoice selectMany">
            <label htmlFor="q8-1" className={classNames({ selected: selectedItems.includes(1) })}>
              <input type="checkbox" defaultChecked={selectedItems.includes(1)} id="q8-1" onClick={() => onSelect(1)} />
              Gazinière / plaques électriques
            </label>

            <label htmlFor="q8-2" className={classNames({ selected: selectedItems.includes(2) })}>
              <input type="checkbox" defaultChecked={selectedItems.includes(2)} id="q8-2" onClick={() => onSelect(2)} />
              Four
            </label>

            <label htmlFor="q8-3" className={classNames({ selected: selectedItems.includes(3) })}>
              <input type="checkbox" defaultChecked={selectedItems.includes(3)} id="q8-3" onClick={() => onSelect(3)} />
              Micro-onde
            </label>

            <label htmlFor="q8-4" className={classNames({ selected: selectedItems.includes(4) })}>
              <input type="checkbox" defaultChecked={selectedItems.includes(4)} id="q8-4" onClick={() => onSelect(4)} />
              Mixeur
            </label>

            <label htmlFor="q8-5" className={classNames({ selected: selectedItems.includes(5) })}>
              <input type="checkbox" defaultChecked={selectedItems.includes(5)} id="q8-5" onClick={() => onSelect(5)} />
              Robot cuiseur
            </label>

            <label htmlFor="q8-6" className={classNames({ selected: selectedItems.includes(6) })}>
              <input type="checkbox" checked={selectedItems.includes(6)} id="q8-6" onClick={() => onSelect(6)} />
              Friteuse
            </label>
          </div>
          <br />
          <div className="text-center" ref={ref}>
            <button type="button" className="cta-salmon" id="ContinueEquipment" onClick={onContinue}>
              Continuer
            </button>
          </div>
        </div>
      </div>
    </LandingPageContainer>
  );
};

export default LandingPageEquipement;
