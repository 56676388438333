import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import LandingPageContainerC from "./LandingPageContainerC";
import { LandingPagePathC } from ".";
import { CurrentUser } from "../../controllers/CurrentUser";


const LandingPageCp7 = () => {
    const [answer, setAnswer] = useState<number | undefined>(CurrentUser.answers.q7);
    const scrollRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();

    //window.scrollTo(0,800);
    useEffect(() => {
      setTimeout(() => {
        scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 500);
    }, []);
    
    const onSelected = (value: number) => {
      setAnswer(value);
      CurrentUser.answers.q7 = value;
    };

    const onContinue = () => {
      CurrentUser.answers.q7 = answer;
  
      setTimeout(() => {
        navigate(LandingPagePathC.Page8);
      }, 500);
    };
  
    return (
      <LandingPageContainerC>
        <div className="dpq-question" id="bcm-q7" data-progress="7" style={{display:"block"}}>
            <h5 className="text-center gray3  my-3" ref={scrollRef}>Le Meal Prep revient moins cher que la préparation de repas classiques.</h5>
            <div className="quickquiz text-center">
                <label className={classNames({ selected: answer === 1 })}
                onClick={() => {
                  onSelected(1);
                }}>
                    <input type="radio" name="bcm-q7" value="1" onClick={() => {
                    onSelected(1);
                  }}/>
                    <strong>VRAI</strong>
                </label>

                <label className={classNames({ selected: answer === 0 })}
                onClick={() => {
                  onSelected(0);
                }}>
                    <input type="radio" name="bcm-q7" value="0" onClick={() => {
                    onSelected(0);
                  }}/>
                    <strong>FAUX</strong>
                </label>
            </div>
            {answer=== 1 && (                    
            <div className="quizResult yes" style={{display:"block"}}>
                <h5 className="salmon"><strong>Tout à fait !</strong></h5>
                <div>Vous pouvez faire pas mal d'économies grâce au Meal Prep Minceur. Comment ? Votre liste de courses est volontairement réduite à 20 produits de saison par semaine, réutilisables dans plusieurs plats. Le prix de revient d'une semaine de Meal Prep est en moyenne de 60 euros pour 14 repas complets. Fini le gaspillage&nbsp;!</div>
            </div>
            )}
            {answer=== 0 && (
            <div className="quizResult no" style={{display:"block"}}>
                <h5 className="salmon"><strong>C’est pourtant vrai... </strong></h5>
                <div>Vous pouvez faire pas mal d'économies grâce au Meal Prep Minceur. Comment ? Votre liste de courses est volontairement réduite à 20 produits de saison par semaine, réutilisables dans plusieurs plats. Le prix de revient d'une semaine de Meal Prep est en moyenne de 60 euros pour 14 repas complets. Fini le gaspillage&nbsp;!</div>
            </div>
            )}
            <br />
            {answer !== undefined && (  
            <div className="text-center continueQuiz">
                <button type="button" className="cta-salmon" onClick={onContinue}>Continuer</button>
            </div>
            )}
        </div>
      </LandingPageContainerC>
    );
  };
  
  export default LandingPageCp7;