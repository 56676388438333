import QAnswer from "../models/QAnswer";
import UserProfile from "../models/UserProfile";
import ApiBase from "./ApiBase";

export default class DietProfileApi extends ApiBase {
  public static addProfile = (gender: number, sid: number, v: number, dpversion: number): Promise<number> => {
    const params = { gender, sid, v, dpversion };
    return this.post("/dietprofile/add", params);
  };

  public static updateProfile = (profile: UserProfile): Promise<number> => {
    return this.put("/dietprofile/update", profile);
  };

  public static getDietProfileABTest = (): Promise<number> => {
    return this.get("/dietprofile/versionTest");
  };

  public static addDietProfileABTest = (abLandingPage: number): Promise<boolean> => {
    const params = { abLandingPage };
    return this.post("/dietprofile/addVersionTest", params);
  };

  public static saveAnswers = (
    profileId: number,
    answers: QAnswer[]
  ): Promise<boolean> => {
    return this.post(`/dietprofile/answers/${profileId}`, answers);
  };

  // public static createProfileWithAnswers = (
  //   profile: UserProfile,
  //   answers: QAnswer[]
  // ): Promise<number> => {
  //   const params = {
  //     profile,
  //     answers,
  //   };
  //   return this.post("/dietprofile/create-with-answers", params);
  // };
}
