import { useNavigate } from "react-router-dom";
import DietProfileContainer from "./DietProfileContainer";
import { DietProfilePath } from ".";
import { useState } from "react";
import classNames from "classnames";
import { CurrentUser } from "../../controllers/CurrentUser";

const DietProfilePoids = () => {
  const [selected, setSelected] = useState(CurrentUser.answers.q10 ?? 0);

  const navigate = useNavigate();

  const onSelect = async (value: number) => {
    setSelected(value);
    CurrentUser.answers.q10 = value;
    await CurrentUser.saveDietProfileAnswers();

    setTimeout(() => {
      navigate(DietProfilePath.LoaderForm);
    }, 500);
  };

  return (
    <DietProfileContainer>
      <div className="dpq-wrapper">
        <div className="dpq-question d-block" data-progress="10">
          <h3 className="text-center  emerald">
            <strong>
              Depuis combien de temps pensez-vous que votre poids est trop élevé
              ?
            </strong>
          </h3>

          <div className="text-center">
            <img
              src="/dietprofile-images/bcm-dp-q10.jpg"
              alt=""
              className="img-fluid mx-auto"
            />
          </div>

          <div className="multipleChoice">
            <label
              onClick={() => onSelect(1)}
              className={classNames({ selected: selected === 1 })}
            >
              Quelques mois
            </label>

            <label
              onClick={() => onSelect(2)}
              className={classNames({ selected: selected === 2 })}
            >
              Plus d’un an
            </label>

            <label
              onClick={() => onSelect(3)}
              className={classNames({ selected: selected === 3 })}
            >
              Depuis toujours
            </label>
          </div>
        </div>
      </div>
    </DietProfileContainer>
  );
};

export default DietProfilePoids;
