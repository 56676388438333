import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import LandingPageContainerC from "./LandingPageContainerC";
import { LandingPagePathC } from ".";
import { CurrentUser } from "../../controllers/CurrentUser";


const LandingPageCp3 = () => {
    const [answer, setAnswer] = useState<number | undefined>(CurrentUser.answers.q3);
    const scrollRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();

    //window.scrollTo(0,800);
    useEffect(() => {
      setTimeout(() => {
        scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 500);
    }, []);
    
    const onSelected = (value: number) => {
      setAnswer(value);
      CurrentUser.answers.q3 = value;
    };

    const onContinue = () => {
      CurrentUser.answers.q3 = answer;
  
      setTimeout(() => {
        navigate(LandingPagePathC.Page4);
      }, 500);
    };
  
    return (
      <LandingPageContainerC>
        <div className="dpq-question" id="bcm-q3" data-progress="3" style={{display:"block"}}>
        <h5 className="text-center gray3 my-3" ref={scrollRef}>Le Meal Prep est forcément monotone car on mange tous les jours la même chose&nbsp;? </h5>
        <div className="quickquiz text-center">
            <label className={classNames({ selected: answer === 1 })}
                onClick={() => {
                  onSelected(1);
                }}>
                <input type="radio" name="bcm-q3" value="1" onClick={() => {
                    onSelected(1);
                  }}/>
                <strong>VRAI</strong>
            </label>

            <label className={classNames({ selected: answer === 0 })}
                onClick={() => {
                  onSelected(0);
                }}>
                <input type="radio" name="bcm-q3" value="0" onClick={() => {
                    onSelected(0);
                  }}/>
                <strong>FAUX</strong>
            </label>
        </div>
        {answer === 1 && (                    
        <div className="quizResult yes" style={{display:"block"}}>
            <h5 className="salmon"><strong>C'est faux !</strong></h5>
            <div>Le programme Meal Prep Minceur te propose chaque semaine des recettes savoureuses, originales et variées qui, en plus, sont très faciles et très rapides à préparer. Que des avantages !</div>
        </div>
        )}

        {answer === 0 && (
        <div className="quizResult no" style={{display:"block"}}>
            <h5 className="salmon"><strong>Vous avez raison, c'est faux&nbsp;!</strong></h5>
            <div>Le programme Meal Prep Minceur te propose chaque semaine des recettes savoureuses, originales et variées qui, en plus, sont très faciles et très rapides à préparer. Que des avantages !</div>
        </div>
        )}
        
        <br />
        {answer !== undefined && (
        <div className="text-center continueQuiz">
            <button type="button" className="cta-salmon" onClick={onContinue}>Continuer</button>
        </div>
        )}
        </div>
      </LandingPageContainerC>
    );
  };
  
  export default LandingPageCp3;