import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Progress } from "reactstrap";
import { LandingPagePath } from ".";
import "../dietprofile/bcm-dietprofile.css";
import "./lp.css";
import BrowserUtil from "../../utils/BrowserUtil";

const LPRoot = () => {
    const [progress, setProgress] = useState(0);
  
    const { pathname } = useLocation();
  
    const navigate = useNavigate();
  
    useEffect(() => {
      switch (pathname.toLowerCase()) {
        case LandingPagePath.SelectIdeal:
          setProgress(2);
          break;
        case LandingPagePath.UserDetails:
          setProgress(3);
          break;
        case LandingPagePath.Figure:
          setProgress(4);
          break;
        case LandingPagePath.Exces:
          setProgress(5);
          break;
        case LandingPagePath.Activity:
          setProgress(6);
          break;
        case LandingPagePath.Connaissez:
          setProgress(7);
          break;
        case LandingPagePath.Equipement:
          setProgress(8);
          break;
        case LandingPagePath.Preparer:
          setProgress(9);
          break;
        case LandingPagePath.Poids:
          setProgress(10);
          break;
        default:
          setProgress(0);
          break;
      }
    }, [pathname]);
  
    return (
      <>
      <section id="MealPrepLandingSection" >
        <div className="text-center mpm-header-logo"><img src="/dietprofile-images/mealprep-landing-logo-v3.png" alt="Meal Prep Minceur" className="img-fluid" /></div>
        <div className="container">

            <div className="mpm-lp-container">
               
                <div className="firstTitle text-center pb-3"><h1 className="px-3">Comment maigrir en y consacrant seulement&nbsp;2&nbsp;h&nbsp;par&nbsp;semaine&nbsp;?</h1>
                
                </div> 
                <div className="content-inner-wrap">
                    <div className="bsh-wrap">
                        <div><img src="/dietprofile-images/woman-eating-bcm_main.jpg" className="img-fluid"/></div>
                        <div className="firstTitle text-center">
                            <h3 className="ft-landing">+rapide +économe +autonome</h3>
                        </div>
                    </div>
                    <div className="d-none d-sm-block" ><br /></div>
                    <div className="bsh-wrap">
                        <h4 className="qTopLabel">Commencez votre analyse meal prep minceur gratuite&nbsp;: </h4>
                        <div className="dpq-white" id="DPQ">
                        {progress > 0 && (
                          <div className="dpq-wrapper">
                            <div className="dpq-progress">
                              <div className="dpq-progress-nav">
                                <div className="progress-retour">
                                  <i className="fal fa-chevron-left"></i>&nbsp;{" "}
                                  <a
                                    href="#non"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      navigate(-1);
                                    }}
                                  >
                                    Étape précédente
                                  </a>
                                </div>
                                <div className="progress-qcount">{progress}/10</div>
                              </div>
                              <Progress value={progress * 10} />
                              <br />
                            </div>
                            
                          </div>
                        )}
                        <Outlet/>
                        </div>
                    </div>
                </div>

            </div>

            {!BrowserUtil.isMobileApp() && (                                     
            <div className="my-4 text-center">
                <p>[&nbsp;<a href="http://mealprep.jeanmichelcohen.fr/privacy" target="_blank"><strong>Mentions Légales</strong></a>&nbsp;]</p>
                <p style={{fontSize:"12px", lineHeight:"130%"}}>
                    &copy; 2023 copyright et éditeur ANXA / powered by ANXA  <br />
                    Reproduction totale ou partielle interdite sans accord préalable.<br />
                    Anxa collecte et traite les données personnelles dans le respect de la loi Informatique et Libertés <br />(Déclaration CNIL No 1787863).
                </p>
            </div>
            )}    

        </div>
    </section>
      </>
    );
  };
  
  export default LPRoot;
  