import moment from "moment";
import ContactApi from "../api/ContactApi";
import ContactMessage from "../models/ContactMessage";
import CookieHelper from "../utils/CookieHelper";
import DietUtil from "../utils/DietUtil";

export class ContactController {
  //making this public so to have direct access to data
  public static message: ContactMessage;
  

  public static initialize() {
    this.message = {};
  }

  public static async addMessage() {
    if (!this.message.contactName || !this.message.email || !this.message.reason || !this.message.message) return false;
    this.message.dateposted = moment().toISOString();
    const x = await ContactApi.AddMessage(this.message);
    return true;
  }
}
