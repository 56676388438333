import { Link, useLocation, useRoutes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import PageNotFound from "./pages/PageNotFound";
import { AppLoadingIndicator } from "./pages/_components/AppLoadingIndicator";
import Root from "./pages/root/Root";
import Home from "./pages/root/Home";
import Faq from "./pages/root/Faq";
import useBreadcrumbs, { createRoutesFromChildren, Route } from "use-react-router-breadcrumbs";
import PageNotFound from "./pages/PageNotFound";
//import RouteErrorBoundary from "./pages/RouteErrorBoundary";
import DrCohen from "./pages/root/DrCohen";
import Programme from "./pages/root/Programme";
import BatchCooking from "./pages/root/BatchCooking";
import WeeklyGuide from "./pages/root/WeeklyGuide";
import ContactInfo from "./pages/contact/ContactInfo";
import Advantages from "./pages/root/Advantages";
import { useEffect } from "react";
import CommentSePasse from "./pages/root/CommentSePasse";
import CommentAdapter from "./pages/root/CommentAdapter";
import ConserverPlatsSemaine from "./pages/root/ConserverPlatsSemaine";
import RechaufferLesServir from "./pages/root/RechaufferLesServir";
import ProduitsLaMaison from "./pages/root/ProduitsLaMaison";
import AlimentsRechauffer from "./pages/root/AlimentsRechauffer";
import AlimentsRefrigerateur from "./pages/root/AlimentsRefrigerateur";
import EquipementBesoin from "./pages/root/EquipementBesoin";
import DietProfileRoot from "./pages/dietprofile/DietProfileRoot";
import DietProfileIdeal from "./pages/dietprofile/DietProfileIdeal";
import DietProfileGender from "./pages/dietprofile/DietProfileGender";
import DietProfileUserDetails from "./pages/dietprofile/DietProfileUserDetails";
import { DietProfilePath } from "./pages/dietprofile";
import DietProfileGraph from "./pages/dietprofile/DietProfileGraph";
import DietProfileFigure from "./pages/dietprofile/DietProfileFigure";
import DietProfileExces from "./pages/dietprofile/DietProfileExces";
import DietProfileActivity from "./pages/dietprofile/DietProfileActivity";
import DietProfileQuiz from "./pages/dietprofile/DietProfileQuiz";
import DietProfileConnaissez from "./pages/dietprofile/DietProfileConnaissez";
import DietProfileEquipement from "./pages/dietprofile/DietProfileEquipement";
import DietProfilePreparer from "./pages/dietprofile/DietProfilePreparer";
import DietProfilePoids from "./pages/dietprofile/DietProfilePoids";
import DietProfileResults from "./pages/dietprofile/DietProfileResults";
import DietProfileLoaderForm from "./pages/dietprofile/DietProfileLoaderForm";
import Conditions from "./pages/root/Conditions";
import Contact from "./pages/contact/Contact";
import Privacy from "./pages/root/Privacy";
import TagManager from 'react-gtm-module';
import LPRoot from "./pages/landingpage/LPRoot";
import { LandingPagePath } from "./pages/landingpage";
import LandingPageIdeal from "./pages/landingpage/LandingPageIdeal";
import LandingPageGender from "./pages/landingpage/LandingPageGender";
import LandingPageActivity from "./pages/landingpage/LandingPageActivity";
import LandingPageConnaissez from "./pages/landingpage/LandingPageConnaissez";
import LandingPageEquipement from "./pages/landingpage/LandingPageEquipement";
import LandingPageExces from "./pages/landingpage/LandingPageExces";
import LandingPageFigure from "./pages/landingpage/LandingPageFigure";
import LandingPageGraph from "./pages/landingpage/LandingPageGraph";
import LandingPagePoids from "./pages/landingpage/LandingPagePoids";
import LandingPagePreparer from "./pages/landingpage/LandingPagePreparer";
import LandingPageQuiz from "./pages/landingpage/LandingPageQuiz";
import LandingPageUserDetails from "./pages/landingpage/LandingPageUserDetails";
import LandingPageLoaderForm from "./pages/landingpage/LandingPageLoaderForm";
import LandingPageResults from "./pages/landingpage/LandingPageResults";
import LandingPageGenderB from "./pages/landingpage-b/LandingPageGenderB";
import LPRootB from "./pages/landingpage-b/LPRootB";
import { LandingPagePathB } from "./pages/landingpage-b";
import LandingPageIdealB from "./pages/landingpage-b/LandingPageIdealB";
import LandingPageUserDetailsB from "./pages/landingpage-b/LandingPageUserDetailsB";
import LandingPageGraphB from "./pages/landingpage-b/LandingPageGraphB";
import LandingPageFigureB from "./pages/landingpage-b/LandingPageFigureB";
import LandingPageExcesB from "./pages/landingpage-b/LandingPageExcesB";
import LandingPageActivityB from "./pages/landingpage-b/LandingPageActivityB";
import LandingPageQuizB from "./pages/landingpage-b/LandingPageQuizB";
import LandingPageConnaissezB from "./pages/landingpage-b/LandingPageConnaissezB";
import LandingPageEquipementB from "./pages/landingpage-b/LandingPageEquipementB";
import LandingPagePreparerB from "./pages/landingpage-b/LandingPagePreparerB";
import LandingPagePoidsB from "./pages/landingpage-b/LandingPagePoidsB";
import LPRootC from "./pages/landingpage-c/LPRootC";
import LandingPageGenderC from "./pages/landingpage-c/LandingPageGenderC";
import { LandingPagePathC } from "./pages/landingpage-c";
import LandingPageCp2 from "./pages/landingpage-c/LandingPageCp2";
import LandingPageCp3 from "./pages/landingpage-c/LandingPageCp3";
import LandingPageCp4 from "./pages/landingpage-c/LandingPageCp4";
import LandingPageCp5 from "./pages/landingpage-c/LandingPageCp5";
import LandingPageCp6 from "./pages/landingpage-c/LandingPageCp6";
import LandingPageCp7 from "./pages/landingpage-c/LandingPageCp7";
import LandingPageCp8 from "./pages/landingpage-c/LandingPageCp8";
import LandingPageCp9 from "./pages/landingpage-c/LandingPageCp9";
import LandingPageCp10 from "./pages/landingpage-c/LandingPageCp10";
import LandingPageCp11 from "./pages/landingpage-c/LandingPageCp11";
import LandingPageUserDetailsC from "./pages/landingpage-c/LandingPageUserDetailsC";
import LandingPageGraphC from "./pages/landingpage-c/LandingPageGraphC";

export const Breadcrumbs = () => {
  const AppRoutes = GenerateAppRoutes();
  const appRouteObjects = createRoutesFromChildren(AppRoutes);

  const breadcrumbs = useBreadcrumbs(appRouteObjects);

  if (breadcrumbs.length === 0) return null;

  return (
    <section className="breadcrumbs-section">
      <div className="container-fluid">
        <div className="breadcrumbs">
          <Link to="/">Accueil</Link>
          <span>/</span>
          <a href="#conseils">Conseils</a>
          <span>/</span>
          {breadcrumbs.map(({ breadcrumb }) => breadcrumb)}
        </div>
      </div>
    </section>
  );
};

export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const tagManagerArgs = {
  gtmId: 'GTM-5W47WZ7'
}

//const tagManagerArgs2 = {
//  gtmId: 'AW-925757960'
//}

TagManager.initialize(tagManagerArgs)
//TagManager.initialize(tagManagerArgs2)

const GenerateAppRoutes = () => {
  return [
    <Route path="/" element={<Root />}>
      <Route index element={<Home />} breadcrumb={null} />
      <Route path="contact-info" element={<ContactInfo />} breadcrumb={null} />
      <Route path="contact" element={<Contact />} breadcrumb="Contactez-nous" />
      <Route path="faq" element={<Faq />} breadcrumb="FAQ" />
      <Route path="conditions" element={<Conditions />} breadcrumb="Conditions générales d'utilisation" />
      <Route path="privacy" element={<Privacy />} breadcrumb="Charte sur la vie privée" />
      
      <Route path="dr-cohen" element={<DrCohen />} breadcrumb="Je suis le Dr Jean-Michel Cohen" />
      <Route path="programme" element={<Programme />} breadcrumb="Tout ce qu’il y a dans le programme !" />
      <Route
        path="meal-prep-cest-quoi"
        element={<BatchCooking />}
        breadcrumb="Le Meal Prep Minceur Dr Cohen, c'est quoi ?"
      />
      <Route path="exemple-de-menus" element={<WeeklyGuide />} breadcrumb="Une semaine de Meal Prep Minceur" />

      <Route path="avantages-meal-prep" element={<Advantages />} breadcrumb="Les 5 avantages du Meal Prep Minceur" />
      <Route
        path="une-semaine-meal-prep"
        element={<CommentSePasse />}
        breadcrumb="1 Semaine de Meal Prep : comment ça se passe ?"
      />
      <Route
        path="meal-prep-pour-famille"
        element={<CommentAdapter />}
        breadcrumb="Comment adapter le Meal Prep Minceur pour toute la famille ?"
      />
      <Route
        path="conserver-plats-semaine"
        element={<ConserverPlatsSemaine />}
        breadcrumb="Comment bien conserver mes plats de la semaine ?"
      />
      <Route
        path="rechauffer-les-servir"
        element={<RechaufferLesServir />}
        breadcrumb="Comment réchauffer les plats et les servir ?"
      />
      <Route
        path="produits-la-maison"
        element={<ProduitsLaMaison />}
        breadcrumb="Quels produits dois-je toujours avoir à la maison ?"
      />
      <Route path="aliments-a-ne-pas-rechauffer" element={<AlimentsRechauffer />} breadcrumb="Quelques notions d’hygiène" />
      <Route
        path="aliments-refrigerateur"
        element={<AlimentsRefrigerateur />}
        breadcrumb="Quels aliments ne se conservent pas au réfrigérateur ?"
      />
      <Route
        path="equipement-meal-prep-minceur"
        element={<EquipementBesoin />}
        breadcrumb="Quel équipement pour mon Meal Prep ?"
      />
    </Route>,
    <Route path="cookingminceur" element={<DietProfileRoot />}>
      <Route index element={<DietProfileGender />} />
      <Route path={DietProfilePath.SelectIdeal} element={<DietProfileIdeal />} />
      <Route path={DietProfilePath.UserDetails} element={<DietProfileUserDetails />} />
      <Route path={DietProfilePath.DietGraph} element={<DietProfileGraph />} />
      <Route path={DietProfilePath.Figure} element={<DietProfileFigure />} />
      <Route path={DietProfilePath.Exces} element={<DietProfileExces />} />
      <Route path={DietProfilePath.Activity} element={<DietProfileActivity />} />
      <Route path={DietProfilePath.Quiz} element={<DietProfileQuiz />} />
      <Route path={DietProfilePath.Connaissez} element={<DietProfileConnaissez />} />
      <Route path={DietProfilePath.Equipement} element={<DietProfileEquipement />} />
      <Route path={DietProfilePath.Preparer} element={<DietProfilePreparer />} />
      <Route path={DietProfilePath.Poids} element={<DietProfilePoids />} />
      <Route path={DietProfilePath.LoaderForm} element={<DietProfileLoaderForm />} />
      {/* <Route path={DietProfilePath.Results} element={<DietProfileResults />} /> */}
    </Route>,
    <Route path="cookingminceur">
      <Route path={DietProfilePath.Results} element={<DietProfileResults />} />
    </Route>,
    <Route path="cooking-minceur-old" element={<LPRoot />}>
      <Route index element={<LandingPageGender />} />
      <Route path={LandingPagePath.SelectIdeal} element={<LandingPageIdeal />} />
      <Route path={LandingPagePath.UserDetails} element={<LandingPageUserDetails />} />
      <Route path={LandingPagePath.DietGraph} element={<LandingPageGraph />} />
      <Route path={LandingPagePath.Figure} element={<LandingPageFigure />} />
      <Route path={LandingPagePath.Exces} element={<LandingPageExces />} />
      <Route path={LandingPagePath.Activity} element={<LandingPageActivity />} />
      <Route path={LandingPagePath.Quiz} element={<LandingPageQuiz />} />
      <Route path={LandingPagePath.Connaissez} element={<LandingPageConnaissez />} />
      <Route path={LandingPagePath.Equipement} element={<LandingPageEquipement />} />
      <Route path={LandingPagePath.Preparer} element={<LandingPagePreparer />} />
      <Route path={LandingPagePath.Poids} element={<LandingPagePoids />} />
    </Route>,
    <Route path="cooking-minceur" element={<DietProfileRoot />}>
      <Route path={LandingPagePath.LoaderForm} element={<LandingPageLoaderForm />} />
    </Route>,
    <Route path="cooking-minceur">
      <Route path={LandingPagePath.Results} element={<LandingPageResults />} />
    </Route>,
    <Route path="cooking-minceur" element={<LPRootB />}>
      <Route index element={<LandingPageGenderB />} />
      <Route path={LandingPagePathB.SelectIdeal} element={<LandingPageIdealB />} />
      <Route path={LandingPagePathB.UserDetails} element={<LandingPageUserDetailsB />} />
      <Route path={LandingPagePathB.DietGraph} element={<LandingPageGraphB />} />
      <Route path={LandingPagePathB.Figure} element={<LandingPageFigureB />} />
      <Route path={LandingPagePathB.Exces} element={<LandingPageExcesB />} />
      <Route path={LandingPagePathB.Activity} element={<LandingPageActivityB />} />
      <Route path={LandingPagePathB.Quiz} element={<LandingPageQuizB />} />
      <Route path={LandingPagePathB.Connaissez} element={<LandingPageConnaissezB />} />
      <Route path={LandingPagePathB.Equipement} element={<LandingPageEquipementB />} />
      <Route path={LandingPagePathB.Preparer} element={<LandingPagePreparerB />} />
      <Route path={LandingPagePathB.Poids} element={<LandingPagePoids />} />
    </Route>,
    <Route path="cooking-minceur-b" element={<DietProfileRoot />}>
      <Route path={LandingPagePathB.LoaderForm} element={<LandingPageLoaderForm />} />
    </Route>,
    <Route path="cooking-minceur-b">
      <Route path={LandingPagePathB.Results} element={<LandingPageResults />} />
    </Route>,
    
    <Route path="cooking-minceur-c" element={<LPRootC />}>
      <Route index element={<LandingPageGenderC />} />
      <Route path={LandingPagePathC.Page2} element={<LandingPageCp2 />} />
      <Route path={LandingPagePathC.Page3} element={<LandingPageCp3 />} />
      <Route path={LandingPagePathC.Page4} element={<LandingPageCp4 />} />
      <Route path={LandingPagePathC.Page5} element={<LandingPageCp5 />} />
      <Route path={LandingPagePathC.Page6} element={<LandingPageCp6 />} />
      <Route path={LandingPagePathC.Page7} element={<LandingPageCp7 />} />
      <Route path={LandingPagePathC.Page8} element={<LandingPageCp8 />} />
      <Route path={LandingPagePathC.Page9} element={<LandingPageCp9 />} />
      <Route path={LandingPagePathC.Page10} element={<LandingPageCp10 />} />
      <Route path={LandingPagePathC.Page11} element={<LandingPageCp11 />} />
      <Route path={LandingPagePathC.UserDetails} element={<LandingPageUserDetailsC />} />
      <Route path={LandingPagePathC.DietGraph} element={<LandingPageGraphC />} />
    </Route>,
    <Route path="cooking-minceur-c" element={<DietProfileRoot />}>
      <Route path={LandingPagePathC.LoaderForm} element={<LandingPageLoaderForm />} />
    </Route>,
    <Route path="cooking-minceur-c">
      <Route path={LandingPagePathC.Results} element={<LandingPageResults />} />
    </Route>,
    <Route path="*" element={<PageNotFound />} />,
  ];
};

function App() {
  const AppRoutes = GenerateAppRoutes();
  const appRouteObjects = createRoutesFromChildren(AppRoutes);
  const GeneratedRoutes = useRoutes(appRouteObjects);

  return (
    <>
      {GeneratedRoutes}
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <AppLoadingIndicator />
    </>
  );
}

export default App;
