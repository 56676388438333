import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import CookieHelper from "../utils/CookieHelper";
import { useSearchParams } from 'react-router-dom';
import StringUtil from "../utils/StringUtil";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { ScrollToTop } from "../App";

interface LayoutProps {
  children: ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  const [searchParams] = useSearchParams();
  const trackingSid = searchParams.get('sid');
  const trackingV = searchParams.get('v');
  
  if (trackingSid && StringUtil.isNumeric(trackingSid))
  {
    CookieHelper.setTrackingSid(parseInt(trackingSid));
  }

  if (trackingV && StringUtil.isNumeric(trackingV))
  {
    CookieHelper.setTrackingV(parseInt(trackingV));
  }

  return (
    <main>
      <ScrollToTop />

      <header id="BatchCookingHeader">
        <div className="container-fluid">
          <div className="headPad">
            <a href="/" className="text-center bcm-header-logo">
              <img
                src="/images/bcm-header-logo-d-v3.png"
                alt="Meal Prep Minceur COHEN"
                className="img-fluid d-none d-sm-block mx-auto"
              />
              <img
                src="/images/bcm-header-logo-d-v3.png"
                alt="Meal Prep Minceur COHEN"
                className="img-fluid d-block d-sm-none mx-auto"
              />
            </a>

            <div className="bcm-top-menu d-none d-lg-block">
              <div className="desktopNav text-center">
                <div>
                  <NavLink to="/dr-cohen" className={({ isActive }) => (isActive ? "active" : "")}>
                    Dr&nbsp;Cohen
                  </NavLink>
                </div>
                <div>
                  <NavLink to="/meal-prep-cest-quoi" className={({ isActive }) => (isActive ? "active" : "")}>
                    Meal Prep&nbsp;?
                  </NavLink>
                </div>
                <div>
                  <NavLink to="/programme" className={({ isActive }) => (isActive ? "active" : "")}>
                    Programme
                  </NavLink>
                </div>
                <div>
                  <NavLink to="/exemple-de-menus" className={({ isActive }) => (isActive ? "active" : "")}>
                    Exemple de&nbsp;menus
                  </NavLink>
                </div>
                <div>
                  <NavLink to="/faq" className={({ isActive }) => (isActive ? "active" : "")}>
                    FAQ
                  </NavLink>
                </div>
                <div>
                  <NavLink to="/contact-info" className={({ isActive }) => (isActive ? "active" : "")}>
                    Contact
                  </NavLink>
                </div>
              </div>
            </div>

            <div className="rightHead">
              <div className="d-none d-lg-block">
                <div className="logreg-buttons" role="group">
                  <a href="/page-de-paiement.asp" className="btn inscription">
                    <span>Inscription</span>
                  </a>
                  <a href="/dashboard/Home/Login" className="btn" style={{ marginLeft: "5px" }}>
                    <span>Connexion</span>
                  </a>
                </div>
                <div className="pb-2">
                  <div className="cn-line">
                    Contactez-nous au <a href="tel:+33411880112*">04&nbsp;11&nbsp;88&nbsp;01&nbsp;12*</a>
                  </div>
                </div>
              </div>

              <div className="d-block d-lg-none">
                <UncontrolledDropdown>
                  <DropdownToggle className="menuMobile">
                    <i className="fas fa-bars"></i>
                  </DropdownToggle>
                  <DropdownMenu end>
                    <Link className="dropdown-item" to="/dr-cohen">
                      Dr Cohen
                    </Link>
                    <Link className="dropdown-item" to="/meal-prep-cest-quoi">
                      Meal Prep ?
                    </Link>
                    <Link className="dropdown-item" to="/programme">
                      Programme
                    </Link>
                    <Link className="dropdown-item" to="/exemple-de-menus">
                      Exemple de menus
                    </Link>
                    <Link className="dropdown-item" to="/faq">
                      FAQ
                    </Link>
                    <Link className="dropdown-item" to="/contact-info">
                      Contact
                    </Link>
                    <DropdownItem divider />
                    <a className="dropdown-item" href="/dashboard/Home/Login">Connexion</a>
                    <a className="dropdown-item" href="/page-de-paiement.asp">Inscription</a>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
          </div>
        </div>
      </header>

      {children}

      {/* <!-- FOOTER --> */}

      <footer id="footerBcm" className="footerFull">
        <div className="footerBlock1">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-4">
                <div className="footerActionBlock">
                  <p>
                    Passez votre analyse minceur <br />
                    gratuitement avec Dr&nbsp;Cohen
                  </p>
                  <div>
                    <Link to="/cooking-minceur" className="cta-salmon">
                      JE COMMENCE
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="footerActionBlock">
                  <p>
                    Notre équipe est à votre écoute. <br />
                    Appelez-nous au 04&nbsp;11&nbsp;88&nbsp;01&nbsp;12
                  </p>
                  <div>
                    <a href="tel:+33411880112" className="cta-salmon">
                      J'APPELLE
                    </a>
                  </div>

                  <div className="smallCallNote">*Coût d'un appel local, du lundi au vendredi de 9H00 à 18h </div>
                </div>
              </div>

              <div className="col-sm-4">
                <div className="footerActionBlock">
                  <p>
                    Retrouvez Jean-Michel Cohen sur <br />
                    <br />
                  </p>
                  <div>
                  <a href="https://www.instagram.com/dr.jeanmichelcohen/" target="_blank" className="smi instagram" title="Instagram"><i className="fab fa-instagram"></i></a>
                  <a href="https://www.facebook.com/regimecohen/" target="_blank" className="smi facebook" title="Facebook"><i className="fab fa-facebook"></i></a>
                  <a href="https://twitter.com/jeanmichelcohen" target="_blank" className="smi twitter" title="Twitter"><i className="fab fa-twitter"></i></a>
                  <a href="https://www.youtube.com/@dr.jeanmichelcohen" target="_blank" className="smi youtube" title="YouTube"><i className="fab fa-youtube"></i></a>
                  <a href="https://www.tiktok.com/@drjeanmichelcohen" target="_blank" className="smi TikTok" title="TikTok"><i className="fab fa-tiktok"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="footerBlock2">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-3">
                <div className="siteLinks">
                  <h5>Assistance</h5>
                    <ul>
                      <li><a href="/page-de-paiement.asp">Inscription</a></li>
                      <li><a href="/dashboard/Home/Login">Connexion</a></li>
                      <li><a href="/faq">Aide - FAQ</a></li>
                      <li><a href="/conditions">Conditions d'utilisation</a></li>
                      <li><a href="/privacy">Charte sur la vie privée</a></li>
                      <li><a href="/contact-info">Contact</a></li>
                    </ul>
                </div>
              </div>
              <div className="col-md-3">
                <div className="siteLinks">
                  <h5>Découvrir</h5>
                    <ul>
                      <li><a href="/cooking-minceur">Faire mon Analyse</a></li>
                      <li><a href="/dr-cohen">Dr Jean-Michel Cohen</a></li>
                      <li><a href="/meal-prep-cest-quoi">Meal Prep Minceur </a></li>
                      <li><a href="/exemple-de-menus">Exemple de menus</a></li>
                      <li><a href="/meal-prep-cest-quoi">Conseils pratiques</a></li>
                    </ul>
                </div>
              </div>
              <div className="col-md-3">
                <div className="siteLinks">
                  <h5>Découvrez nos applis</h5>
                  <div className="d-flex align-items-center">
                      <p>
                          <img src="images/mealprep-mobile-app.png" alt="Meal Prep Minceur Dr Cohen" className="img-fluid" style={{maxWidth:"150px"}} />
                      </p>
                      <div className="text-center">
                          <a href="https://play.google.com/store/apps/details?id=com.anxa.batchcooking&referrer=utm_source%3DMealprep_APP_Minisite%26utm_medium%3Dfooter%26anid%3Dadmob" target="_blank" title="Disponible sur Google Play"><img src="images/google-play-disponible_btn-stroke.png" alt="Disponible sur Google Play" className="mx-auto img-fluid p-1" style={{maxWidth:"150px"}} /></a><br />
                          <a href="https://apps.apple.com/fr/app/meal-prep-minceur/id6447055146" target="_blank" title="Disponible sur App Store"><img src="images/appstore-disponible_btn-stroke.png" alt="Disponible sur App Store" className="mx-auto img-fluid  p-1" style={{maxWidth:"150px"}} /></a>
                      </div>
                  </div>
                </div>
              </div>
            
                        <div className="col-md-3">
                            <div className="siteLinks">
                                <h5>Disclaimer </h5>
                                <p className="disclaimerText">
                                    Les témoignages présentés sont des expériences individuelles. Ils ne sont ni caractéristiques, ni garantis : les résultats peuvent varier d'une personne à l'autre. Comme pour tout programme de rééquilibrage alimentaire, des plans de repas contrôlés et des exercices physiques réguliers sont nécessaires pour perdre du poids à long terme. Demandez toujours l'avis de votre médecin traitant avant d'entreprendre un régime amincissant, un programme sportif ou de modifier vos habitudes nutritionnelles.
                                </p>
                            </div>
                        </div>
                    </div>

          </div>
        </div>

        <div className="footerBlock3">
          <div className="container">
            <p className="copyrightText">
              © 2023 copyright et éditeur ANXA / powered by ANXA <br />
              Reproduction totale ou partielle interdite sans accord préalable. <br />
              Anxa collecte et traite les données personnelles dans le respect de la loi Informatique et Libertés
              (Déclaration CNIL No 1787863).
            </p>
          </div>
        </div>
      </footer>
    </main>
  );
};

export default Layout;
