import { useNavigate } from "react-router-dom";
import LandingPageContainer from "./LandingPageContainer";
import { LandingPagePath } from ".";
import { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { Fade } from "reactstrap";
import { CurrentUser } from "../../controllers/CurrentUser";

const LandingPagePreparer = () => {
  const [selected, setSelected] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();
  const scrollRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setTimeout(() => {
      scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 500);
  }, []);

  const onSelect = (value: number) => {
    setSelected(value);
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }, 500);
  };

  const onContinue = () => {
    CurrentUser.answers.q9 = selected;

    setTimeout(() => {
      navigate(LandingPagePath.Poids);
    }, 500);
  };

  return (
    <LandingPageContainer>
      <div className="dpq-wrapper">
        <div className="dpq-question d-block" data-progress="9">
          <h3 className="text-center  emerald">
            <strong ref={scrollRef}>2 Heures pour préparer tous vos repas de la semaine, ça vous semble :</strong>
          </h3>

          <div className="multipleChoice">
            <label onClick={() => onSelect(1)} className={classNames({ selected: selected === 1 })}>
              Facile
            </label>

            <label onClick={() => onSelect(2)} className={classNames({ selected: selected === 2 })}>
              Peu probable
            </label>

            <label onClick={() => onSelect(3)} className={classNames({ selected: selected === 3 })}>
              Impossible
            </label>

            <label onClick={() => onSelect(4)} className={classNames({ selected: selected === 4 })}>
              Je ne sais pas
            </label>
          </div>

          {selected === 1 ? (
            <Fade in={true} className="quizResult d-block" key="1">
              <div>
                Vous avez confiance et vous avez raison&nbsp;! En profitant des petits secrets du Meal Prep Minceur,
                vous allez cuisiner avec un maximum d’organisation et d’efficacité pour tout plier en 2 heures&nbsp;!
              </div>
            </Fade>
          ) : selected !== 0 ? (
            <Fade in={true} className="quizResult d-block" key="2">
              <div>
                Vous avez du mal à y croire et pourtant&nbsp;! C’est le petit secret du Meal Prep Minceur : cuisiner
                avec un maximum d’organisation et d’efficacité pour tout plier en 2 heures&nbsp;! Chiche&nbsp;?
              </div>
            </Fade>
          ) : null}

          <br />
          {selected !== 0 && (
            <div className="text-center" ref={ref}>
              <button type="button" className="cta-salmon" id="Continue2heures" onClick={onContinue}>
                Continuer
              </button>
            </div>
          )}
        </div>
      </div>
    </LandingPageContainer>
  );
};

export default LandingPagePreparer;
