import Axios, { AxiosInstance } from 'axios';
import Constants from '../Constants';
//import UserHelper from '../helpers/UserHelper';
import ErrorResponse from '../models/ErrorResponse';

export default class ApiBase {
    static axios: AxiosInstance;

    public static _initialize() {
        
        this.axios = Axios.create({
            baseURL: Constants.API_URL,
            headers: { "Access-Control-Allow-Origin": "*"}
        });
        console.log('_initialize', Constants.API_URL)
        // Add a response interceptor
        this.axios.interceptors.response.use(
            function (response) {
                // Any status code that lie within the range of 2xx cause this function to trigger
                // Do something with response data
                return response;
            },
            function (error) {
               // console.error(error.config);
                let errorResponse: ErrorResponse = {
                    status: 0,
                    title: '',
                    errors: undefined,
                    message: '',
                    name: ''
                };
                if (error.response) {
                    const data = error.response.data;
                    if (data.status && data.title && data.errors) {
                        errorResponse.status = data.status;
                        errorResponse.title = data.title;
                        errorResponse.errors = data.errors;
                    } else {
                        errorResponse.status = error.response.status;
                        errorResponse.message = data;
                    }
                } else if (error.request) {
                    // The request was made but no response was received
                    errorResponse.message = 'No response from server';
                } else {
                    // Something happened in setting up the request that triggered an Error
                    errorResponse.message = 'Error: ' + error.message;
                }
                // Any status codes that falls outside the range of 2xx cause this function to trigger
                // Do something with response error
                console.error(errorResponse);
                return Promise.reject(errorResponse);
            }
        );

        this.axios.interceptors.request.use(
            function (config) {
               // const idToken = UserHelper.getIdToken();
               // if (config.headers && idToken) {
               //     config.headers.Authorization = idToken;
               // }
                return config;
            },
            function (error) {
                // Do something with request error
                return Promise.reject(error);
            }
        );
    }

    public static post(url: string, params: any): Promise<any> {
        return this.axios.post(url, params).then((res) => {
            return res.data;
        });
    }

    public static delete(url: string): Promise<any> {
        return this.axios.delete(url).then((res) => {
            return res.data;
        });
    }

    public static get(url: string, params?: any): Promise<any> {
        return this.axios.get(url, { params }).then((res) => {
            //return null if no response, instead of a blank data
            if (res.status === 204) {
                return null;
            }
            return res.data;
        });
    }

    public static put(url: string, params: any): Promise<any> {
        return this.axios.put(url, params).then((res) => {
            return res.data;
        });
    }

    public static patch(url: string, params: any): Promise<any> {
        return this.axios.patch(url, params).then((res) => {
            return res.data;
        });
    }

    public static postData(url: string, formData: any): Promise<any> {
        return this.axios.post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then((res) => {
            return res.data;
        });
    }

    public static async uploadPhoto(url: string, images?: FileList | null | undefined): Promise<string> {
        if (images && images.length > 0) {
            const formData = new FormData();
            formData.append('file', images[0]);
            const imageUrl: string = await ApiBase.postData(url, formData);
            return imageUrl;
        }
        return '';
    }
}

ApiBase._initialize();
