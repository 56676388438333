import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import LandingPageContainerC from "./LandingPageContainerC";
import { LandingPagePathC } from ".";
import { CurrentUser } from "../../controllers/CurrentUser";


const LandingPageCp2 = () => {
    const [answer, setAnswer] = useState<number | undefined>(CurrentUser.answers.q4);
    const scrollRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();

    //window.scrollTo(0,800);
    useEffect(() => {
      setTimeout(() => {
        scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 500);
    }, []);
    
    const onSelected = (value: number) => {
      setAnswer(value);
      CurrentUser.answers.q4 = value;
    };

    const onContinue = () => {
      CurrentUser.answers.q4 = answer;
  
      setTimeout(() => {
        navigate(LandingPagePathC.Page6);
      }, 500);
    };
  
    return (
      <LandingPageContainerC>
        <div className="dpq-question" id="bcm-q5" data-progress="5" style={{display:"block"}}>
            <h5 className="text-center gray3 my-3" ref={scrollRef}>Le principe du Meal Prep est incompatible avec le fait de manger équilibré&nbsp;?</h5>
                
            <div className="quickquiz text-center">
                <label className={classNames({ selected: answer === 1 })}
                onClick={() => {
                  onSelected(1);
                }}>
                    <input type="radio" name="bcm-q5" value="1" onClick={() => {
                    onSelected(1);
                  }}/>
                    <strong>VRAI</strong>
                </label>

                <label className={classNames({ selected: answer === 0 })}
                onClick={() => {
                  onSelected(0);
                }}>
                    <input type="radio" name="bcm-q5" value="0" onClick={() => {
                    onSelected(0);
                  }}/>
                    <strong>FAUX</strong>
                </label>
            </div>

            {answer=== 1 && (
            <div className="quizResult yes" style={{display:"block"}}>
                <h5 className="salmon"><strong>C'est faux !</strong></h5>
                <div>Et c'est même la principale valeur ajoutée du programme Meal Prep Minceur. Les plans de repas sont, chaque semaine, élaborés par le nutritionniste Jean-Michel Cohen et son équipe diététique. Parfaitement équilibré sur le plan nutritionnel, il correspond à une valeur calorique de 1600 kcal/jour. Juste ce qu'il faut pour perdre du poids de façon harmonieuse et régulière.</div>
            </div>
            )}

            {answer=== 0 && (
            <div className="quizResult no" style={{display:"block"}}>
                <h5 className="salmon"><strong>Bien joué, c'est faux !</strong></h5>
                <div>Et c'est même la principale valeur ajoutée du programme Meal Prep Minceur. Les plans de repas sont, chaque semaine, élaborés par le nutritionniste Jean-Michel Cohen et son équipe diététique. Parfaitement équilibré sur le plan nutritionnel, il correspond à une valeur calorique de 1600 kcal/jour. Juste ce qu'il faut pour perdre du poids de façon harmonieuse et régulière.</div>
            </div>
            )}
            <br />
            {answer !== undefined && (  
            <div className="text-center continueQuiz">
                <button type="button" className="cta-salmon" onClick={onContinue}>Continuer</button>
            </div>
            )}
        </div>
      </LandingPageContainerC>
    );
  };
  
  export default LandingPageCp2;