import { Spinner } from "reactstrap";
import styled from "styled-components";

interface PulseLoaderProps {
  size?: string;
  margin?: string;
}

export const PulseLoader = ({ size = "1.75rem", margin = "5px -2px" }: PulseLoaderProps) => {
  return (
    <LoaderContainer className="pulse-loader">
      <PulseLoaderIcon type="grow" className="pulse1" width={size} height={size} margin={margin} />
      <PulseLoaderIcon type="grow" className="pulse2" width={size} height={size} margin={margin} />
      <PulseLoaderIcon type="grow" className="pulse3" width={size} height={size} margin={margin} />
    </LoaderContainer>
  );
};

const LoaderContainer = styled.div<PulseLoaderProps>`
  width: 100%;
  height: 100%;
  padding-bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 75px;
`;

interface PulseLoaderIconProps {
  width: string;
  height: string;
  margin: string;
}

const PulseLoaderIcon = styled(Spinner)<PulseLoaderIconProps>`
  color: #ff771f;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin: ${(props) => props.margin};
  &.pulse1 {
    animation-timing-function: cubic-bezier(0.5, 0.75, 0.5, 1);
    animation-delay: 0s;
  }
  &.pulse2 {
    animation-timing-function: cubic-bezier(0.5, 0.75, 0.5, 1);
    animation-delay: 0.15s;
  }
  &.pulse3 {
    animation-timing-function: cubic-bezier(0.5, 0.75, 0.5, 1);
    animation-delay: 0.25s;
  }
`;
