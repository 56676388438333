import { useEffect } from "react";
import CommencerCard from "../shared/CommencerCard";

const Privacy = () => {
  useEffect(() => {
    document.title = 'Charte sur la vie privée - Meal Prep Minceur Dr Cohen';
  }, []);
  
  return (
    <section className="level2-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-8">
            <div className="bcm-leftcol-wrap">
            <h1 className="emerald"><strong>Charte sur la vie privée</strong></h1>

<p>L'entité Anxa Ltd a déclaré la collecte et le traitement des données personnelles de ses utilisateurs auprès de la Commission Nationale de l'informatique et des Libertés.</p>
<p>L'utilisateur s'engage à accepter le fait que les forums, ainsi que les informations qu’il aura lui-même choisi de diffuser sur le site (pseudonyme - situation géographique – photo) soient consultables par de tierces personnes.</p>
<p>Les informations personnelles de l'ensemble des utilisateurs sont recueillies, utilisées et divulguées en conformité des dispositions contenues dans cette Charte, et dans le respect des réglementations.</p>

<br/>
<hr/>
<br/>
<div className="cgv-sections" id="an-1">
    <h4 className="salmon">DÉFINITION DES DONNÉES PERSONNELLES </h4>
    <p>La sociétés Anxa Ltd peut être amenée, dans le cadre de leurs activités, à recueillir des informations personnelles. Ceci regroupe notamment les noms, prénoms, adresses électroniques, adresses personnelles, ou encore les numéros de téléphone. Ces données personnelles sont collectées afin de permettre à Anxa Ltd d'identifier leurs utilisateurs et d'améliorer en permanence la qualité de leurs services. Enfin, grâce à ces données, Anxa Ltd dispose de statistiques générales sur l'utilisation des services proposés.</p>
    <p>Anxa Ltd ne collecte, n'utilise et ne divulgue pas certaines informations personnelles sensibles, notamment liées à l'origine, à la religion ou aux affiliations politiques de leurs utilisateurs.</p>
</div>
<br/>
<div className="cgv-sections" id="an-2">
    <h4 className="salmon">COLLECTE ET UTILISATION DES DONNÉES PERSONNELLES </h4>


    <p>Les informations personnelles recueillies peuvent être sauvegardées et traitées en France ou dans tout autre pays dans lequel Anxa Ltd ou leurs filiales, succursales ou agents disposent de locaux. En utilisant les services "Programmes de coaching en ligne", l'utilisateur accepte le transfert de ces données en dehors de son pays.</p>
    <p>
        Anxa Ltd collecte les données personnelles de leurs utilisateurs afin de :
    </p><ul>
        <li>fournir les services demandés (certaines informations sont obligatoires afin d'accéder par e-mail aux services d'Anxa Ltd);</li>
        <li>communiquer des conseils personnalisés, c'est à dire remplir leur obligation vis à vis de l'utilisateur achetant ces services;</li>
        <li>informer l'utilisateur d'autres produits ou services;</li>
        <li>mener des enquêtes d'opinion et de satisfaction via des questionnaires, sur des services actuels et futurs.</li>
    </ul>


</div>
<br/>
<div className="cgv-sections" id="an-3">
    <h4 className="salmon">PARTAGE ET DIVULGATION DES DONNÉES PERSONNELLES </h4>

    <p>Anxa Ltd s'engage à ne divulguer en aucune façon les données personnelles de leurs utilisateurs, excepté dans les cas suivants:</p>
    <ul>
        <li>accord préalable de l'utilisateur;</li>
        <li>demande d'une autorité judiciaire ou obligation légale;</li>
        <li>obligation de partager des données personnelles avec des partenaires prestataires afin de fournir de meilleurs services et de répondre aux attentes des utilisateurs.</li>
    </ul>

</div>
<br/>
<div className="cgv-sections" id="an-4">
    <h4 className="salmon">SÉCURITÉ DES INFORMATIONS PERSONNELLES </h4>

    <p>Anxa Ltd s'engage à protéger la sécurité des informations personnelles communiquées par leurs utilisateurs. L'accès en est limité et la protection assurée par les meilleures technologies et procédures de sécurité.</p>
    <p>Un cryptage particulier des données sensibles et confidentielles, telles que les coordonnées de carte bancaire, est utilisé, en relation avec les fournisseurs de solutions de paiement d'Anxa Ltd.</p>


</div>
<br/>
<div className="cgv-sections" id="an-5">
    <h4 className="salmon">COOKIES ET BALISES WEB </h4>

    <p>Anxa Ltd utilise des cookies afin d'offrir à leurs utilisateurs les meilleurs services possibles, grâce à l'ensemble des fonctionnalités disponibles par internet. Les cookies sont des fichiers texte placés sur le disque dur de l'utilisateur par l'un des serveurs d'Anxa Ltd. Totalement inactif et sécurisé, le cookie est attribué de façon unique à l'ordinateur destinataire et peut être lu uniquement par le serveur Anxa Ltd émetteur.</p>
    <p>Un cookie permettra à Anxa Ltd de simplifier le paiement et la livraison de leurs services. Les informations fournies lors d'une première connexion sur les serveurs d'Anxa Ltd pourront être récupérées afin de simplifier l'utilisation des services.</p>
    <p>L'utilisateur a la possibilité, en modifiant les paramètres de son navigateur Web, de neutraliser et de refuser les cookies, s'il le souhaite. Dans ce cas, il se peut que l'utilisateur ne puisse pas bénéficier des services d'Anxa Ltd.</p>
    <p>Les balises Web sont des "marquages" électroniques contenus notamment dans des images (GIF) situées sur les pages des services Anxa Ltd . et des e-mails. Il s'agit d'une technique utilisée afin de collecter des données statistiques sur l'audience et les visites des pages Anxa Ltd. Ces balises recueille des données limitées, telles que l'heure et la date de la visite.</p>
    <b>Comment paramétrer votre navigateur pour refuser le ciblage publicitaire ou accepter à nouveau le ciblage publicitaire ? </b>
    <p>
        Vous pouvez à tout moment choisir de désactiver ces cookies. Votre navigateur peut également être paramétré pour vous signaler les cookies qui sont déposés dans votre ordinateur et vous demander de les accepter ou pas. <br/>
        Vous pouvez accepter ou refuser les cookies au cas par cas ou bien les refuser systématiquement.<br/>
        Nous vous rappelons que le paramétrage est susceptible de modifier vos conditions d'accès à nos contenus et services nécessitant l'utilisation de cookies.<br/>
        Si votre navigateur est configuré de manière à refuser l'ensemble des cookies, vous ne pourrez pas profiter d'une partie de nos services. Afin de gérer les cookies au plus près de vos attentes nous vous invitons à paramétrer votre navigateur en tenant compte de la finalité des cookies.
    </p>
    <b>Internet Explorer</b>
    <p>
        Dans Internet Explorer, cliquez sur le bouton Outils, puis sur Options Internet.<br/>
        Sous l'onglet Général, sous Historique de navigation, cliquez sur Paramètres.<br/>
        Cliquez sur le bouton Afficher les fichiers.
    </p>
    <b>Firefox</b>
    <p>
        Allez dans l'onglet Outils du navigateur puis sélectionnez le menu Options<br/>
        Dans la fenêtre qui s'affiche, choisissez Vie privée et cliquez sur Affichez les cookies
    </p>
    <b>Safari</b>
    <p>
        Dans votre navigateur, choisissez le menu Édition &gt; Préférences.<br/>
        Cliquez sur Sécurité.<br/>
        Cliquez sur Afficher les cookies. <br/>
    </p>
    <b>Google Chrome </b>
    <p>
        Cliquez sur l'icône du menu Outils.<br/>
        Sélectionnez Options.<br/>
        Cliquez sur l'onglet Options avancées et accédez à la section Confidentialité.<br/>
        Cliquez sur le bouton Afficher les cookies.
    </p>

</div>

<br/>
<div className="cgv-sections" id="an-6">
    <h4 className="salmon">CONSERVATION DES DONNÉES PERSONNELLES </h4>
    <p>Anxa Ltd s'engage à conserver les données personnelles, collectées après accord de leurs utilisateurs, dans le respect des dispositions légales de la loi de janvier 1978 et de ses textes d'application.</p>
</div>
<br/>
<div className="cgv-sections" id="an-7">
    <h4 className="salmon">DROITS DES UTILISATEURS DE MODIFICATION ET DE SUPPRESSION DES DONNÉES PERSONNELLES </h4>

    <p>
        <b>Droit de refus et de contrôle</b><br/>
        La possibilité est laissée à l'utilisateur de refuser la collecte de données personnelles le concernant. Il peut également refuser de recevoir des annonces commerciales, des questionnaires ou tout autre forme de promotion ou activité marketing, de la part de Anxa Ltd. ou de la part de partenaires.
    </p>
    <p>
        <b>Droit de modification</b><br/>
        L'utilisateur a le droit d'accéder à ses données personnelles et d'en demander la rectification ou la modification.
    </p>
    <p>
        <b>Droit de suppression</b><br/>
        L'utilisateur a un droit de suppression de l'ensemble des données personnelles le concernant, ceci à tout moment et sur simple demande, par e-mail, par courrier postal ou par téléphone.
    </p>
    <p>
        Tout utilisateur peut exercer ses droits : <br/>
    </p><ul style={{paddingBottom: "0px", marginBottom: "0px", paddingLeft:"20px"}}>
        <li>en ligne, en remplissant <a href="/contact">le formulaire de contact</a></li>
        <li>
            en envoyant un courrier postal à l'adresse suivante : <br/>
            <strong>
                Aujourdhui.com / Anxa<br/>
                Buro Club<br/>
                543 Rue de la Castelle<br/>
                34070 Montpellier<br/>
                Tel : +33 4 11 88 01 12
            </strong><br/>
        </li>
    </ul>

</div>

<br/>
<div className="cgv-sections" id="an-8">
    <h4 className="salmon">QUESTIONS ET SUGGESTION </h4>


    <p>
        Toute personne est encouragée à soumettre à Anxa ses questions, suggestions ou remarques au sujet de cette Charte sur la Vie Privée. Si vous pensez après la lecture de cette Charte, qu'Anxa Ltd. ne respectent pas leurs engagements, contactez nous par courrier postal à l'adresse :<br/>
        <strong>
            Aujourdhui.com / Anxa<br/>
            Buro Club<br/>
            543 Rue de la Castelle<br/>
            34070 Montpellier<br/>
            Tel : +33 4 11 88 01 12
        </strong><br/>
    </p>



</div>
<br/>
            </div>
          </div>
          <div className="col-lg-4">
            <CommencerCard />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Privacy;
