import { Outlet } from "react-router-dom";
import { Breadcrumbs } from "../../App";
import Layout from "../Layout";
import BrowserUtil from "../../utils/BrowserUtil";
import React from "react";

const Root = () => {
  if (BrowserUtil.isMobileApp()) {
    return (
      <React.Fragment>
        <Outlet />
      </React.Fragment>
    );
  }

  return (
    <Layout>
      <Breadcrumbs />
      <Outlet />
    </Layout>
  );
};

export default Root;
