import { Link } from "react-router-dom";
import BrowserUtil from "../../utils/BrowserUtil";

interface Props {
  hideTitle?: boolean;
}

const VosQuestions = (props: Props) => {
  return (
    <>
      {!props.hideTitle && (
        <>
          <h2 className="emerald">
            <strong>Vos questions</strong>
          </h2>
          <br />
        </>
      )}
      <ul>
        <li>
          <p>
            <Link className="gray3" to="/meal-prep-cest-quoi" onClick={BrowserUtil.scrollToTop}>
              <strong>Le Meal Prep Minceur Dr Cohen, c'est quoi ? </strong>
            </Link>
          </p>
        </li>
        <li>
          <p>
            <Link className="gray3" to="/avantages-meal-prep" onClick={BrowserUtil.scrollToTop}>
              <strong>Quels sont les avantages du Meal Prep Minceur ?</strong>
            </Link>
          </p>
        </li>
        <li>
          <p>
            <Link className="gray3" to="/une-semaine-meal-prep" onClick={BrowserUtil.scrollToTop}>
              <strong>1 Semaine de Meal Prep : comment ça se passe ?</strong>
            </Link>
          </p>
        </li>
        <li>
          <p>
            <Link className="gray3" to="/meal-prep-pour-famille" onClick={BrowserUtil.scrollToTop}>
              <strong>Comment adapter le Meal Prep Minceur pour toute la famille ? </strong>
            </Link>
          </p>
        </li>
        <li>
          <p>
            <Link className="gray3" to="/conserver-plats-semaine" onClick={BrowserUtil.scrollToTop}>
              <strong>Comment bien conserver mes plats de la semaine ?</strong>
            </Link>
          </p>
        </li>
        <li>
          <p>
            <Link className="gray3" to="/rechauffer-les-servir" onClick={BrowserUtil.scrollToTop}>
              <strong>Comment réchauffer les plats et les servir ?</strong>
            </Link>
          </p>
        </li>
        <li>
          <p>
            <Link className="gray3" to="/produits-la-maison" onClick={BrowserUtil.scrollToTop}>
              <strong>Quels produits dois-je toujours avoir à la maison ?</strong>
            </Link>
          </p>
        </li>
        <li>
          <p>
            <Link className="gray3" to="/aliments-a-ne-pas-rechauffer" onClick={BrowserUtil.scrollToTop}>
              <strong>Quels aliments ne faut-il jamais réchauffer ? </strong>
            </Link>
          </p>
        </li>
        <li>
          <p>
            <Link className="gray3" to="/aliments-refrigerateur" onClick={BrowserUtil.scrollToTop}>
              <strong>Quels aliments ne se conservent pas au réfrigérateur ?</strong>
            </Link>
          </p>
        </li>
        <li>
          <p>
            <Link className="gray3" to="/equipement-meal-prep-minceur" onClick={BrowserUtil.scrollToTop}>
              <strong>De quel équipement ai-je besoin ?</strong>
            </Link>
          </p>
        </li>
      </ul>
    </>
  );
};

export default VosQuestions;
