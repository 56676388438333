export default class DietUtil {  


    public static BMI = (height: number, weight: number): number  => {
       if (height > 0 && weight > 0) {
            return parseFloat((weight / (height/100 * height/100)).toFixed(1));
       }
       return 0;

    };

  
  
}
