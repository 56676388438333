import { Carousel } from "@trendyol-js/react-carousel";
import { useRef } from "react";
import styled from "styled-components";
import VosQuestions from "../shared/VosQuestions";

const WeeklyGuide = () => {
  const mealPlanRef = useRef<HTMLDivElement>(null);
  const coursesRef = useRef<HTMLDivElement>(null);
  const preparationRef = useRef<HTMLDivElement>(null);

  const RightArrow = () => {
    return (
      <ArrowContainer title="view more">
        <ArrowIcon className="fa fa-chevron-right" />
      </ArrowContainer>
    );
  };

  const LeftArrow = () => {
    return (
      <ArrowContainer title="view previous">
        <ArrowIcon className="fa fa-chevron-left ml-2" />
      </ArrowContainer>
    );
  };

  return (
    <>
      <section className="level2-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="bcm-fullwidth-wrap">
                <h1 className="emerald">
                  <strong>Une semaine de Meal Prep Minceur</strong>
                </h1>

                <div className="anchors">
                  <div>
                    <a href="#Menus" onClick={() => mealPlanRef.current?.scrollIntoView({ behavior: "smooth" })}>
                      Menus
                    </a>
                  </div>
                  <div>
                    <a href="#Courses" onClick={() => coursesRef.current?.scrollIntoView({ behavior: "smooth" })}>
                      Liste de courses
                    </a>
                  </div>
                  <div>
                    <a href="#Methode" onClick={() => preparationRef.current?.scrollIntoView({ behavior: "smooth" })}>
                      Méthode{" "}
                    </a>
                  </div>
                  <div>
                    <a href="/meal-prep-week-free.pdf" target="_blank">
                      Ebook
                    </a>
                  </div>
                </div>

                <div className="bcm-weekly-guide-wrap">
                  <div className="wg-meal-plan" ref={mealPlanRef}>
                    <h2 className="text-center salmon">
                      <strong>On mange quoi cette semaine ?</strong>
                    </h2>

                    <div className="mp-dayWrap">
                      <div className="cpMealflex">
                        <div className="cpImage">
                          <div className="mp-image"><img src="images/bcm-wg-meal-pd.jpg" className="img-fluid" /></div>
                        </div>
                        <div className="cpContent">
                          <div className="dayMeals">
                            <div className="text-center"><h4 className="dayName">Petit déjeuner</h4></div>

                            <div className="row align-items-center">
                              <div className="col-12">
                                <div className="mp-mobile-image"><img src="images/bcm-wg-meal-pd.jpg" className="img-fluid" /></div>
                              </div>
                              <div className="col-lg-6">

                                <ul style={{listStyle:"none"}}>
                                  <li><div><em>Tous les matins, vous pouvez composer votre petit déjeuner en respectant le cadre suivant&nbsp;:</em></div></li>
                                </ul>
                              </div>
                              <div className="col-lg-6">
                                <ul>
                                  <li>Boisson chaude sans sucre ou édulcorée : café, thé, infusion...</li>
                                  <li>60 g de pain de boulangerie</li>
                                  <li>10 g de matières grasses</li>
                                  <li>1 laitage nature à maxi 4% de MG</li>
                                  <li>1 fruit de saison (pomme, poire, etc.) ou compote ou salade de fruits</li>
                                </ul>
                              </div>
                            </div>
                          </div>

                        </div>

                      </div>
                    </div>

                    <div className="mp-dayWrap">
                        <div className="cpMealflex">
                            <div className="cpContent">
                                <div className="dayMeals">
                                    <div className="text-center"><h4 className="dayName">Lundi</h4></div>

                                    <div className="row">
                                        <div className="col-12">
                                            <div className="mp-mobile-image"><img src="images/mpm-boeuf-braise-poireaux-640.jpg" className="img-fluid" /></div>
                                        </div>
                                        <div className="col-lg-6">
                                            <h5><strong className="salmon">Déjeuner</strong></h5>
                                            <ul>
                                                <li><strong className="gray3">Bowl de riz complet, thon, avocat, sauce yaourt</strong></li>
                                              <li>1 tranche/morceau de pain de 40 g environ.</li>
                                              <li>1 portion de fromage ou yaourt nature ou fromage blanc ou 2 petits-suisses</li>
                                              <li>1 fruit de saison (pomme, poire, etc.) ou compote ou salade de fruits</li>
                                              <li>2 verres d’eau </li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-6">
                                            <h5><strong className="salmon">Dîner</strong></h5>
                                            <ul>
                                                
                                                <li><strong className="gray3">Bœuf braisé aux poireaux façon Cohen</strong></li>
                                              <li>1 tranche/morceau de pain de 40 g environ.</li>
                                              <li>1 portion de fromage ou yaourt nature ou fromage blanc ou 2 petits-suisses</li>
                                              <li>1 fruit de saison (pomme, poire, etc.) ou compote ou salade de fruits</li>
                                              <li>2 verres d’eau </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="cpImage">
                                <div className="mp-image"><img src="images/mpm-boeuf-braise-poireaux-640.jpg" className="img-fluid" /></div>
                            </div>
                        </div>
                    </div>

                    <div className="mp-dayWrap">
                        <div className="cpMealflex">
                            <div className="cpImage">
                                <div className="mp-image"><img src="images/mpm-salade-pates-640.jpg" className="img-fluid" /></div>
                            </div>
                            <div className="cpContent">
                                <div className="dayMeals">
                                    <div className="text-center"><h4 className="dayName">Mardi</h4></div>

                                    <div className="row">
                                        <div className="col-12">
                                            <div className="mp-mobile-image"><img src="images/mpm-salade-pates-640.jpg" className="img-fluid" /></div>
                                        </div>
                                        <div className="col-lg-6">
                                            <h5><strong className="salmon">Déjeuner</strong></h5>
                                            <ul>
                                                
                                                <li><strong className="gray3">Salade de pâtes complètes aux œufs durs, tomates et maïs</strong></li>
                                                <li>1 tranche/morceau de pain de 40 g environ.</li>
                                                <li>1 portion de fromage ou yaourt nature ou fromage blanc ou 2 petits-suisses</li>
                                                <li>1 fruit de saison (pomme, poire, etc.) ou compote ou salade de fruits</li>
                                                <li>2 verres d’eau </li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-6">
                                            <h5><strong className="salmon">Dîner</strong></h5>
                                            <ul>
                                                <li><strong className="gray3">Gratin de légumes au jambon et béchamel légère</strong></li>
                                                <li>1 tranche/morceau de pain de 40 g environ.</li>
                                                <li>1 portion de fromage ou yaourt nature ou fromage blanc ou 2 petits-suisses</li>
                                                <li>1 fruit de saison (pomme, poire, etc.) ou compote ou salade de fruits</li>
                                                <li>2 verres d’eau </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                    <div className="mp-dayWrap">
                        <div className="cpMealflex">
                            <div className="cpContent">
                                <div className="dayMeals">
                                    <div className="text-center"><h4 className="dayName">Mercredi</h4></div>

                                    <div className="row">
                                        <div className="col-12">
                                            <div className="mp-mobile-image"><img src="images/mpm-sandwich-jambon-640.jpg" className="img-fluid" /></div>
                                        </div>
                                        <div className="col-lg-6">
                                            <h5><strong className="salmon">Déjeuner</strong></h5>
                                            <ul>
                                                <li><strong className="gray3">Sandwich au jambon et légumes</strong></li>
                                                <li>1 tranche/morceau de pain de 40 g environ.</li>
                                                <li>1 portion de fromage ou yaourt nature ou fromage blanc ou 2 petits-suisses</li>
                                                <li>1 fruit de saison (pomme, poire, etc.) ou compote ou salade de fruits</li>
                                                <li>2 verres d’eau </li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-6">
                                            <h5><strong className="salmon">Dîner</strong></h5>
                                            <ul>
                                                <li><strong className="gray3">Bowl de riz complet, thon, avocat, sauce yaourt</strong></li>
                                                <li>1 tranche/morceau de pain de 40 g environ.</li>
                                                <li>1 portion de fromage ou yaourt nature ou fromage blanc ou 2 petits-suisses</li>
                                                <li>1 fruit de saison (pomme, poire, etc.) ou compote ou salade de fruits</li>
                                                <li>2 verres d’eau </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="cpImage">
                                <div className="mp-image"><img src="images/mpm-sandwich-jambon-640.jpg" className="img-fluid" /></div>
                            </div>
                        </div>
                    </div>

                    <div className="mp-dayWrap">
                        <div className="cpMealflex">
                            <div className="cpImage">
                                <div className="mp-image"><img src="images/mpm-boeuf-braise-poireaux-640.jpg" className="img-fluid" /></div>
                            </div>
                            <div className="cpContent">
                                <div className="dayMeals">
                                    <div className="text-center"><h4 className="dayName">Jeudi</h4></div>

                                    <div className="row">
                                        <div className="col-12">
                                            <div className="mp-mobile-image"><img src="images/mpm-boeuf-braise-poireaux-640.jpg" className="img-fluid" /></div>
                                        </div>
                                        <div className="col-lg-6">
                                            <h5><strong className="salmon">Déjeuner</strong></h5>
                                            <ul>
                                                <li><strong className="gray3">Bœuf braisé aux poireaux façon Cohen</strong></li>
                                                <li>1 tranche/morceau de pain de 40 g environ.</li>
                                                <li>1 portion de fromage ou yaourt nature ou fromage blanc ou 2 petits-suisses</li>
                                                <li>1 fruit de saison (pomme, poire, etc.) ou compote ou salade de fruits</li>
                                                <li>2 verres d’eau </li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-6">
                                            <h5><strong className="salmon">Dîner</strong></h5>
                                            <ul>
                                                <li><strong className="gray3">Salade de pâtes complètes aux œufs durs, tomates et maïs</strong></li>
                                                <li>1 tranche/morceau de pain de 40 g environ.</li>
                                                <li>1 portion de fromage ou yaourt nature ou fromage blanc ou 2 petits-suisses</li>
                                                <li>1 fruit de saison (pomme, poire, etc.) ou compote ou salade de fruits</li>
                                                <li>2 verres d’eau </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                    <div className="mp-dayWrap">
                        <div className="cpMealflex">
                            <div className="cpContent">
                                <div className="dayMeals">
                                    <div className="text-center"><h4 className="dayName">Vendredi</h4></div>

                                    <div className="row">
                                        <div className="col-12">
                                            <div className="mp-mobile-image"><img src="images/mpm-wrap-thon-640.jpg" className="img-fluid" /></div>
                                        </div>
                                        <div className="col-lg-6">
                                            <h5><strong className="salmon">Déjeuner</strong></h5>
                                            <ul>
                                                <li><strong className="gray3">Gratin de légumes au jambon et béchamel légère</strong></li>
                                                <li>1 tranche/morceau de pain de 40 g environ.</li>
                                                <li>1 portion de fromage ou yaourt nature ou fromage blanc ou 2 petits-suisses</li>
                                                <li>1 fruit de saison (pomme, poire, etc.) ou compote ou salade de fruits</li>
                                                <li>2 verres d’eau </li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-6">
                                            <h5><strong className="salmon">Dîner</strong></h5>
                                            <ul>
                                                <li><strong className="gray3">Wrap thon, légumes croquants et fromage</strong></li>
                                                <li>1 tranche/morceau de pain de 40 g environ.</li>
                                                <li>1 portion de fromage ou yaourt nature ou fromage blanc ou 2 petits-suisses</li>
                                                <li>1 fruit de saison (pomme, poire, etc.) ou compote ou salade de fruits</li>
                                                <li>2 verres d’eau </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="cpImage">
                                <div className="mp-image"><img src="images/mpm-wrap-thon-640.jpg" className="img-fluid" /></div>
                            </div>
                        </div>
                    </div>

                    <div className="mp-dayWrap">
                        <div className="cpMealflex">
                            <div className="cpImage">
                                <div className="mp-image"><img src="images/mpm-bowl-thon-640.jpg" className="img-fluid" /></div>
                            </div>
                            <div className="cpContent">
                                <div className="dayMeals">
                                    <div className="text-center"><h4 className="dayName">Samedi</h4></div>

                                    <div className="row">
                                        <div className="col-12">
                                            <div className="mp-mobile-image"><img src="images/mpm-bowl-thon-640.jpg" className="img-fluid" /></div>
                                        </div>
                                        <div className="col-lg-6">
                                            <h5><strong className="salmon">Déjeuner</strong></h5>
                                            <ul>
                                                <li><strong className="gray3">Bowl de riz complet, thon, avocat, sauce yaourt</strong></li>
                                                <li>1 tranche/morceau de pain de 40 g environ.</li>
                                                <li>1 portion de fromage ou yaourt nature ou fromage blanc ou 2 petits-suisses</li>
                                                <li>1 fruit de saison (pomme, poire, etc.) ou compote ou salade de fruits</li>
                                                <li>2 verres d’eau </li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-6">
                                            <h5><strong className="salmon">Dîner</strong></h5>
                                            <ul>
                                                <li><strong className="gray3">Bœuf braisé aux poireaux façon Cohen</strong></li>
                                                <li>1 tranche/morceau de pain de 40 g environ.</li>
                                                <li>1 portion de fromage ou yaourt nature ou fromage blanc ou 2 petits-suisses</li>
                                                <li>1 fruit de saison (pomme, poire, etc.) ou compote ou salade de fruits</li>
                                                <li>2 verres d’eau </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                    <div className="mp-dayWrap">
                        <div className="cpMealflex">
                            <div className="cpContent">
                                <div className="dayMeals">
                                    <div className="text-center"><h4 className="dayName">Dimanche</h4></div>

                                    <div className="row">
                                        <div className="col-12">
                                            <div className="mp-mobile-image"><img src="images/mpm-gratin-legumes-et-jambon-640.jpg" className="img-fluid" /></div>
                                        </div>
                                        <div className="col-lg-6">
                                            <h5><strong className="salmon">Déjeuner</strong></h5>
                                            <ul>
                                                <li><strong className="gray3">Salade de pâtes complètes aux œufs durs, tomates et maïs</strong></li>
                                                <li>1 tranche/morceau de pain de 40 g environ.</li>
                                                <li>1 portion de fromage ou yaourt nature ou fromage blanc ou 2 petits-suisses</li>
                                                <li>1 fruit de saison (pomme, poire, etc.) ou compote ou salade de fruits</li>
                                                <li>2 verres d’eau </li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-6">
                                            <h5><strong className="salmon">Dîner</strong></h5>
                                            <ul>
                                                <li><strong className="gray3">Gratin de légumes au jambon et béchamel légère</strong></li>
                                                <li>1 tranche/morceau de pain de 40 g environ.</li>
                                                <li>1 portion de fromage ou yaourt nature ou fromage blanc ou 2 petits-suisses</li>
                                                <li>1 fruit de saison (pomme, poire, etc.) ou compote ou salade de fruits</li>
                                                <li>2 verres d’eau </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="cpImage">
                                <div className="mp-image"><img src="images/mpm-gratin-legumes-et-jambon-640.jpg" className="img-fluid" /></div>
                            </div>
                        </div>
                    </div>
                  </div>

                  {/* <!-- PREPARATION + RECIPE --> */}
                  <div className="wg-preparation" ref={preparationRef}>
                    <h2 className="emerald text-center">
                      <strong>On cuisine quoi cette semaine&nbsp;?</strong>
                    </h2>
                    <div className="text-center">
                      <h4 className="cost-tag">Mode d'emploi du Meal Prep Minceur (2 heures environ)</h4>
                    </div>

                    <div className="cuisineStepsWrapper">


                                    {/*  <!-- step --> */}
                                    <div className="chapterBlock">
                                       
                                        <div className="textColumn">
                                            <div className="cuisine-steps">
                                                <div className="numberCol">
                                                    <div className="stepNumber">1</div>
                                                </div>
                                                <div className="contentCol">
                                                    <div className="prepContent">
														<h5 className="my-3">Lancer le chrono, c'est parti ! Mettre les œufs à cuire pour la salade de pâtes.</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

									{/*  <!-- step --> */}
									<div className="chapterBlock">

										<div className="textColumn">
											<div className="cuisine-steps">
												<div className="numberCol">
													<div className="stepNumber">2</div>
												</div>
												<div className="contentCol">
													<div className="prepContent">
														<h5 className="my-3">Couper tous les légumes des 4 recettes (d'un coup !) et égoutter les boîtes de thon et de maïs. <span className="timing">(20 mins)</span></h5>
													</div>
												</div>
											</div>
										</div>
									</div>
                                   
									{/*  <!-- step --> */}
									<div className="chapterBlock">

										<div className="textColumn">
											<div className="cuisine-steps">
												<div className="numberCol">
													<div className="stepNumber">3</div>
												</div>
												<div className="contentCol">
													<div className="prepContent">
														<h5 className="my-3">Préparer le riz et les pâtes, cuire en parallèle. <span className="timing">(15 mins)</span></h5>
													</div>
												</div>
											</div>
										</div>
									</div>

                                     
									{/*  <!-- step --> */}
									<div className="chapterBlock">

										<div className="textColumn">
											<div className="cuisine-steps">
												<div className="numberCol">
													<div className="stepNumber">4</div>
												</div>
												<div className="contentCol">
													<div className="prepContent">
														<h5 className="my-3">Sortir les œufs du feu, les laisser refroidir. Pendant ce temps, danser un peu devant le four. <span className="timing">(2 mins)</span></h5>
													</div>
												</div>
											</div>
										</div>
									</div>

									{/*  <!-- step --> */}
									<div className="chapterBlock">

										<div className="textColumn">
											<div className="cuisine-steps">
												<div className="numberCol">
													<div className="stepNumber">5</div>
												</div>
												<div className="contentCol">
													<div className="prepContent">
														<h5 className="my-3">Préchauffer le four à 180°C pour le gratin puis préparer la béchamel pour le gratin. <span className="timing">(8 mins)</span></h5>
													</div>
												</div>
											</div>
										</div>
									</div>

									{/*  <!-- step --> */}
									<div className="chapterBlock">

										<div className="textColumn">
											<div className="cuisine-steps">
												<div className="numberCol">
													<div className="stepNumber">6</div>
												</div>
												<div className="contentCol">
													<div className="prepContent">
														<h5 className="my-3">Assembler et enfourner le gratin de légumes. Pendant la cuisson du gratin, siffler un air joyeux. <span className="timing">(5 mins)</span></h5>
													</div>
												</div>
											</div>
										</div>
									</div>

									{/*  <!-- step --> */}
									<div className="chapterBlock">

										<div className="textColumn">
											<div className="cuisine-steps">
												<div className="numberCol">
													<div className="stepNumber">7</div>
												</div>
												<div className="contentCol">
													<div className="prepContent">
														<h5 className="my-3">Préparer la sauce pour le bowl de riz et assembler le bowl de riz (3 portions). <span className="timing">(8 mins)</span></h5>
													</div>
												</div>
											</div>
										</div>
									</div>


									{/*  <!-- step --> */}
									<div className="chapterBlock">

										<div className="textColumn">
											<div className="cuisine-steps">
												<div className="numberCol">
													<div className="stepNumber">8</div>
												</div>
												<div className="contentCol">
													<div className="prepContent">
														<h5 className="my-3">Préparer la vinaigrette pour la salade de pâtes. <span className="timing">(5 mins)</span></h5>
													</div>
												</div>
											</div>
										</div>
									</div>

									{/*  <!-- step --> */}
									<div className="chapterBlock">

										<div className="textColumn">
											<div className="cuisine-steps">
												<div className="numberCol">
													<div className="stepNumber">9</div>
												</div>
												<div className="contentCol">
													<div className="prepContent">
														<h5 className="my-3">Écaler les œufs durs, les couper en quartiers. <span className="timing">(5 mins)</span></h5>
													</div>
												</div>
											</div>
										</div>
									</div>

									{/*  <!-- step --> */}
									<div className="chapterBlock">

										<div className="textColumn">
											<div className="cuisine-steps">
												<div className="numberCol">
													<div className="stepNumber">10</div>
												</div>
												<div className="contentCol">
													<div className="prepContent">
														<h5 className="my-3">Assembler la salade de pâtes (3 portions) et vérifier le gratin, le sortir s'il est prêt. <span className="timing">(5 mins)</span></h5>
													</div>
												</div>
											</div>
										</div>
									</div>


									{/*  <!-- step --> */}
									<div className="chapterBlock">

										<div className="textColumn">
											<div className="cuisine-steps">
												<div className="numberCol">
													<div className="stepNumber">11</div>
												</div>
												<div className="contentCol">
													<div className="prepContent">
														<h5 className="my-3">Faire revenir la viande hachée pour le boeuf braisé puis ajouter tomates, ail, échalotes, oignons à la viande. <span className="timing">(10 mins)</span></h5>
													</div>
												</div>
											</div>
										</div>
									</div>

									{/*  <!-- step --> */}
									<div className="chapterBlock">

										<div className="textColumn">
											<div className="cuisine-steps">
												<div className="numberCol">
													<div className="stepNumber">12</div>
												</div>
												<div className="contentCol">
													<div className="prepContent">
														<h5 className="my-3">Cuire les poireaux à la vapeur puis les ajouter à la viande, laisser mijoter. <span className="timing">(15 mins)</span></h5>
													</div>
												</div>
											</div>
										</div>
									</div>

									{/*  <!-- step --> */}
									<div className="chapterBlock">

										<div className="textColumn">
											<div className="cuisine-steps">
												<div className="numberCol">
													<div className="stepNumber">13</div>
												</div>
												<div className="contentCol">
													<div className="prepContent">
														<h5 className="my-3">Assaisonner le boeuf braisé, puis diviser en 3 portions. Diviser le gratin en 3 portions. <span className="timing">(8 mins)</span></h5>
													</div>
												</div>
											</div>
										</div>
									</div>

									{/*  <!-- step --> */}
									<div className="chapterBlock">

										<div className="textColumn">
											<div className="cuisine-steps">
												<div className="numberCol">
													<div className="stepNumber">14</div>
												</div>
												<div className="contentCol">
													<div className="prepContent">
														<h5 className="my-3">Voilà, vous êtes  un chef étoilé, alors nettoyez la cuisine, en chantant à tue-tête. Bravo&nbsp;! <span className="timing">(12 mins)</span></h5>
													</div>
												</div>
											</div>
										</div>
									</div>
								  
                                </div>

								<br />
								<hr />


								{/*<!-- ============== -->*/}

								<h2 className="salmon text-center mb-3">Recettes de la semaine</h2>
								 

								<div className="cuisineStepsWrapper">
									<div className="chapterBlock chapterRecipe">
										 
										<div className="textColumn mx-auto">
											<div className="rsWrap ">

												<div className="recipeSlider">
													{/*<!--recipe slider -->*/}
                          <Carousel
                              show={1}
                              slide={1}
                              swiping={true}
                              responsive={true}
                              swipeOn={0.1}
                              rightArrow={<RightArrow />}
                              leftArrow={<LeftArrow />}
                            >
													<div key="carousel1" className="recipeStep">
														<div className="cuisine-steps">

															<div className="contentCol">
																<div className="wg-recipe-block">
															    
																	<div className="row">
																		<div className="col-lg-12 text-center">
																			<div className="wg-recipe-title">
																				<h3 className="emerald"><strong>Bœuf braisé aux poireaux façon Cohen</strong></h3>
																			</div>
																			<div className="wg-recipe-image" style={{maxWidth:"360px"}}>
																				<img src="images/mpm-boeuf-braise-poireaux-640.jpg" alt="Recette" className="img-fluid"/>
																			</div>
																		</div>
																	</div>

																	<div className="row">
																		<div className="col-lg-6">
																			<div className="wg-recipe-ingredients">
																				<div className="inLabeler"><h4>Ingrédients</h4></div>
																				<p><strong>Pour 3 portions :</strong> </p>
																				<ul>
																					<li>400 g de viande hachée de bœuf à 5% de MG</li>
																					<li>300 g de tomates concassées en conserve</li>
																					<li>600 g de blanc de poireau</li>
																					<li>Ail, échalotes et oignons (selon vos préférences)</li>
																					<li>Curry, sel et poivre</li>
																				</ul>
																			 
																			</div>
																		</div>
																		<div className="col-lg-6">
																			<div className="wg-recipe-instructions">
																				<div className="inLabeler"><h4>Instructions</h4></div>
																				<ul>
																					<li>Dans la cocotte, faire revenir sans huile la viande hachée avec l'ail, les échalotes et les oignons.</li>
																					<li>En milieu de cuisson, ajouter les tomates concassées en conserve.</li>
																					<li>Dans une cocotte, cuire quelques minutes à la vapeur des poireaux coupés en rondelles.</li>
																					<li>Lorsque les poireaux sont encore fermes, les ajouter à la viande, laisser mijoter.</li>
																					<li>Ajouter en fin de cuisson du curry, du sel et du poivre selon vos préférences.</li>
																				</ul>

																			</div>
																		</div>
																	</div>
																</div>

															</div>
														</div>
													</div>

													<div key="carousel2" className="recipeStep">
														<div className="cuisine-steps">

															<div className="contentCol">
																<div className="wg-recipe-block">

																	<div className="row">
																		<div className="col-lg-12 text-center">
																			<div className="wg-recipe-title">
																				<h3 className="emerald"><strong>Gratin de légumes au jambon et béchamel légère</strong></h3>
																			</div>
																			<div className="wg-recipe-image" style={{maxWidth:"360px"}}>
																				<img src="images/mpm-gratin-legumes-et-jambon-640.jpg" alt="Recette" className="img-fluid"/>
																			</div>
																		</div>
																	</div>

																	<div className="row">
																		<div className="col-lg-6">
																			<div className="wg-recipe-ingredients">
																				<div className="inLabeler"><h4>Ingrédients</h4></div>
																				<p><strong>Pour 3 portions :</strong> </p>
																				<ul>
																					<li>600 g de chou-fleur</li>
																					<li>600 g de brocolis</li>
																					<li>6 carottes</li>
																					<li>12 tranches de jambon</li>
																					<li>120 cl de lait écrémé</li>
																					<li>6 cuillères à soupe de farine</li>
																					<li>3 cuillères à soupe de beurre</li>
																					<li>Noix de muscade, sel, poivre</li>
																				</ul>

																			</div>
																		</div>
																		<div className="col-lg-6">
																			<div className="wg-recipe-instructions">
																				<div className="inLabeler"><h4>Instructions</h4></div>
																				<ul>
																					<li>Préchauffer le four à 180°C.</li>
																					<li>Laver et couper les légumes en morceaux. Cuire les légumes à la vapeur.</li>
																					<li>Préparer la béchamel légère.</li>
																					<li>Dans un plat à gratin, disposer les légumes et le jambon coupé en morceaux.</li>
																					<li>Verser la béchamel sur le tout et enfourner pour 25-30 minutes.</li>

																				</ul>

																			</div>
																		</div>
																	</div>
																</div>

															</div>
														</div>
													</div>

													<div key="carousel3" className="recipeStep">
														<div className="cuisine-steps">

															<div className="contentCol">
																<div className="wg-recipe-block">

																	<div className="row">
																		<div className="col-lg-12 text-center">
																			<div className="wg-recipe-title">
																				<h3 className="emerald"><strong>Bowl de riz complet, thon, avocat, concombre et sauce légère au yaourt</strong></h3>
																			</div>
																			<div className="wg-recipe-image" style={{maxWidth:"360px"}}>
																				<img src="images/mpm-bowl-thon-640.jpg" alt="Recette" className="img-fluid"/>
																			</div>
																		</div>
																	</div>

																	<div className="row">
																		<div className="col-lg-6">
																			<div className="wg-recipe-ingredients">
																				<div className="inLabeler"><h4>Ingrédients</h4></div>
																				<p><strong>Pour 3 portions :</strong> </p>
																				<ul>
																					<li>300 g de riz complet</li>
																					<li>3 boîtes de thon au naturel (140 g chacune)</li>
																					<li>3 avocats</li>
																					<li>3 concombres</li>
																					<li>3 yaourts nature</li>
																					<li>Moutarde, jus de citron, sel, poivre</li>
																				</ul>

																			</div>
																		</div>
																		<div className="col-lg-6">
																			<div className="wg-recipe-instructions">
																				<div className="inLabeler"><h4>Instructions</h4></div>
																				<ul>
																					<li>Cuire le riz complet selon les instructions du paquet.</li>
																					<li>Égoutter le thon.</li>
																					<li>Couper l'avocat et le concombre en dés.</li>
																					<li>Préparer la sauce en mélangeant le yaourt, la moutarde, le jus de citron, le sel et le poivre.</li>
																					<li>Assembler le tout dans un bol.</li>
																				</ul>

																			</div>
																		</div>
																	</div>
																</div>

															</div>
														</div>
													</div>

													<div key="carousel4" className="recipeStep">
														<div className="cuisine-steps">

															<div className="contentCol">
																<div className="wg-recipe-block">

																	<div className="row">
																		<div className="col-lg-12 text-center">
																			<div className="wg-recipe-title">
																				<h3 className="emerald"><strong>Salade de pâtes complètes aux œufs durs, tomates et maïs</strong></h3>
																			</div>
																			<div className="wg-recipe-image" style={{maxWidth:"360px"}}>
																				<img src="images/mpm-salade-pates-640.jpg" alt="Recette" className="img-fluid"/>
																			</div>
																		</div>
																	</div>

																	<div className="row">
																		<div className="col-lg-6">
																			<div className="wg-recipe-ingredients">
																				<div className="inLabeler"><h4>Ingrédients</h4></div>
																				<p><strong>Pour 3 portions :</strong> </p>
																				<ul>
																					<li>300 g de pâtes complètes</li>
																					<li>6 œufs durs</li>
																					<li>6 tomates</li>
																					<li>3 petites boîtes de maïs (140 g chacune)</li>
																					<li>Huile d'olive, vinaigre, sel, poivre</li>
																				</ul>

																			</div>
																		</div>
																		<div className="col-lg-6">
																			<div className="wg-recipe-instructions">
																				<div className="inLabeler"><h4>Instructions</h4></div>
																				<ul>
																					<li>Cuire les pâtes complètes selon les instructions du paquet.</li>
																					<li>Cuire les œufs durs.</li>
																					<li>Couper les tomates en dés.</li>
																					<li>Égoutter le maïs.</li>
																					<li>Mélanger tous les ingrédients dans un saladier et assaisonner avec l'huile, le vinaigre, le sel et le poivre.</li>
																				</ul>

																			</div>
																		</div>
																	</div>
																</div>

															</div>
														</div>
													</div>
                          </Carousel>
												</div>
  
											</div>
										</div>
									</div>
								</div>

								<br />
								<hr />


								{/*<!-- ============== -->*/}

								<h2 className="salmon text-center">Repas de complément</h2>
								<p className="text-center">Les deux repas qui suivent sont simples à préparer, équilibrés et utilisent des ingrédients déjà présents dans la liste des recettes.</p>

								<div className="cuisineStepsWrapper">
									<div className="chapterBlock chapterRecipe">

										<div className="textColumn mx-auto">
											<div className="rsWrap ">
                        
												<div className="recipeSlider">
													{/*<!--recipe slider -->*/}
                          <Carousel
                              show={1}
                              slide={1}
                              swiping={true}
                              responsive={true}
                              swipeOn={0.1}
                              rightArrow={<RightArrow />}
                              leftArrow={<LeftArrow />}
                            >
													<div key="carousel1" className="recipeStep">
														<div className="cuisine-steps">

															<div className="contentCol">
																<div className="wg-recipe-block">

																	<div className="row">
																		<div className="col-lg-12 text-center">
																			<div className="wg-recipe-title">
																				<h3 className="emerald"><strong>Wrap thon, légumes croquants et fromage frais</strong></h3>
																				<h5 style={{color:"#333"}}>Fruit de saison (pomme, poire, etc.)</h5>
																			</div>
																			<br />
																		</div>
																	</div>

																	<div className="row">
																		<div className="col-lg-6">
																			<div className="wg-recipe-image" style={{maxWidth:"360px"}}>
																				<img src="images/mpm-wrap-thon-640.jpg" alt="Recette" className="img-fluid"/>
																			</div>
																		</div>
																		<div className="col-lg-6">
																			<div className="wg-recipe-instructions">
																				
																				 
																					
																				 
																				<div className="inLabeler"><h4>Instructions</h4></div>
																				<ul>
																					<li>Égoutter le thon (restant) et émietter avec une fourchette.</li>
																					<li>Laver et éplucher les carottes (restantes), puis les râper. Laver le concombre (restant) et le couper en fines lamelles.</li>
																					<li>Sortir 2 galettes de blé (ou tortillas) et étaler une cuillère à soupe de fromage frais sur chacune.</li>
																					<li>Répartir le thon émietté, les carottes râpées, les lamelles de concombre et la roquette ou les jeunes pousses d'épinard sur les galettes.</li>
																					<li>Rouler les galettes et les couper en deux.</li>
																				</ul>

																			</div>
																		</div>
																	</div>
																</div>

															</div>
														</div>
													</div>

													<div key="carousel2" className="recipeStep">
														<div className="cuisine-steps">

															<div className="contentCol">
																<div className="wg-recipe-block">

																	<div className="row">
																		<div className="col-lg-12 text-center">
																			<div className="wg-recipe-title">
																				<h3 className="emerald"><strong>Sandwich au jambon et légumes</strong></h3>
																				<h5 style={{color:"#333"}}>Yaourt nature ou fromage blanc <br />
																					Fruit de saison (pomme, poire, etc.)
																				</h5>  
																			</div>
																			<br />
																		</div>
																	</div>

																	<div className="row">
																		<div className="col-lg-6">

																			<div className="wg-recipe-image" style={{maxWidth:"360px"}}>
																				<img src="images/mpm-sandwich-jambon-640.jpg" alt="Recette" className="img-fluid"/>
																			</div>

																			 
																		</div>
																		<div className="col-lg-6">
																			<div className="wg-recipe-instructions">
																				<div className="inLabeler"><h4>Instructions</h4></div>
																				<ul>
																					<li>Utilisez le jambon restant de la recette 2. Coupez-le en fines tranches.</li>
																					<li>Tartinez du pain complet avec un peu de moutarde ou de mayonnaise légère.</li>
																					<li>Ajoutez des feuilles de laitue et des tranches de tomates.</li>
																					<li>Ajoutez les tranches de jambon.</li>
																					<li>Terminez le sandwich avec une autre tranche de pain complet.</li>
																					<li>Accompagnez le sandwich d'un yaourt nature ou d'une portion de fromage blanc.</li>
																					<li>Terminez le repas avec un fruit de saison.</li>
																				</ul>

																			</div>
																		</div>
																	</div>
																</div>

															</div>
														</div>
													</div>
                          </Carousel>
												</div>

											</div>
										</div>
									</div>
								</div>
                  </div>

                  {/* <!-- COURSES  --> */}
                  <div className="wg-courses" ref={coursesRef}>
                    <h2 className="text-center">
                      <strong>On achète quoi cette semaine&nbsp;?</strong>
                    </h2>
                    <div className="text-center">
                      <h4 className="cost-tag">Prix estimé : 60 € environ </h4>
                    </div>

                    <div className="row">
                    <div className="col-lg-6">
										<div className="course-category-block">
											<div className="cc-head">
												<h4>Epicerie <i className="fal fa-shopping-basket"></i></h4>

											</div>
											<ul>
												<li>300 g riz complet</li>
												<li>300 g pâtes complètes</li>
												<li>2 galettes de blé ou tortillas</li>
												<li>300g tomates concassées en conserve</li>
												<li>Maïs en conserve (1 boîte)</li>
												<li>Curry, ail</li>
												<li>3 échalotes, 2 oignons</li>
											</ul>
										</div>

									


										<div className="course-category-block">
											<div className="cc-head">
												<h4>Protéines  <i className="fal fa-meat"></i></h4>

											</div>
											<ul>
												<li>400 g Viande hachée de boeuf à 5% de MG : </li>
												<li>400 g Jambon : 400 g </li>
												<li>4 boîtes Thon en conserve (140 g) </li>
												<li>6 Œufs </li>
											</ul>
										</div>

									</div>

									<div className="col-lg-6">
										<div className="course-category-block">
											<div className="cc-head">
												<h4>Fruits et Légumes <i className="fal fa-carrot"></i></h4>

											</div>
											<ul>
												<li>1 Chou-fleur </li>
												<li>1 Brocoli </li>
												<li>4 Carottes</li>
												<li>600 g Poireaux </li>
												<li>3 grosses tomates</li>
												<li>1 Concombre </li>
												<li>1 salade Roquette </li>
												<li>21 fruits de saison, compote de fruit ou salade de fruit pour chaque repas</li>
											</ul>
										</div>

										<div className="course-category-block">
											<div className="cc-head">
												<h4>Laitages  <i className="fal fa-cheese-swiss"></i></h4>

											</div>
											<ul>
												<li>Fromage frais (type St Môret pour le wrap)</li>
												<li>Yaourts nature, fromage blanc, petits-suisses ou portions de fromage (au choix pour 21 repas)</li>
											</ul>
										</div> 
									

									</div>
                    </div>
                  </div>

                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-gray">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <VosQuestions />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WeeklyGuide;

const ArrowContainer = styled.button`
  width: 40px;
  height: 40px;
  margin-top: calc(50vh - 20px);
  border-radius: 20px;
  border: none;
  margin-left: 5px;
  margin-right: 5px;

  background-color: gray;
  padding-top: 5px;
  transition-duration: 0.3s;

  :hover {
    opacity: 0.7;
  }

  :active {
    -webkit-box-shadow: inset 1px 1px 10px #333;
    -moz-box-shadow: inset 1px 1px 10px #333;
    box-shadow: inset 1px 1px 10px #333;
  }
`;

const ArrowIcon = styled.i`
  font-size: 1.5rem;
  color: lightgray;
  width: 100%;
`;
