import React from "react";
import { Provider } from "react-redux";
import { store } from "./store/store";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
//import ErrorBoundary from "./pages/ErrorBoundary";
import ErrorBoundary from "./error-boundary";
import { BrowserRouter } from "react-router-dom";
import { CurrentUser } from "./controllers/CurrentUser";
//import "bootstrap/dist/css/bootstrap.min.css";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

CurrentUser.initialize();

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
