import moment from 'moment';

export default class DateUtil {
    public static DayNames = ['', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'];

    public static DayName = (d: number) => this.DayNames[d];

    //current day of the week, 1-7 monday=1, tues=2 ...
    public static get CurrentDay() {
        const d = new Date().getDay();
        return d === 0 ? 7 : d;
    }

    public static format = (d: Date, format = 'date') => {
        if (format === 'date') return moment(d).format('l');

        return moment(d).calendar();
    };

    public static addDays(numOfDays: number, date = new Date()) {
        date.setDate(date.getDay() + numOfDays);
      
        return date;
      }
}