import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import LandingPageContainer from "./LandingPageContainer";
import { LandingPagePath } from ".";
import { CurrentUser } from "../../controllers/CurrentUser";


const LandingePageIdeal = () => {
    const [plan, setPlan] = useState(CurrentUser.answers.q2 ?? 0);
    const scrollRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();

    //window.scrollTo(0,800);
    useEffect(() => {
      setTimeout(() => {
        scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 500);
    }, []);
    
    const onSelected = (value: number) => {
      setPlan(value);
      CurrentUser.answers.q2 = value;
      
      setTimeout(() => {
        navigate(LandingPagePath.UserDetails);
      }, 500);
    };
  
    return (
      <LandingPageContainer>
        <div className="dpq-wrapper">
          <div className="dpq-question d-block" id="bcm-q2" data-progress="2">
            <h3 className="text-center  emerald">
              <strong ref={scrollRef}>Pour perdre du poids, l’idéal pour vous c’est&nbsp;:</strong>
            </h3>
            <div className="text-center">
              <img src="/dietprofile-images/bcm-dp-q1.jpg" alt="" className="img-fluid mx-auto" />
            </div>
            <div className="multipleChoice">
              <label
                className={classNames({ selected: plan === 1 })}
                onClick={() => {
                  onSelected(1);
                }}
              >
                Je mange de tout et j’ai juste besoin d’un programme minceur équilibré, économique et efficace.
              </label>
              <label
                className={classNames({ selected: plan === 2 })}
                onClick={() => {
                  onSelected(2);
                }}
              >
                J’ai une alimentation spécifique (végétarien, sans gluten…), besoin d’un suivi diététique et j’ai le temps de cuisiner.
              </label>
              <label
                className={classNames({ selected: plan === 3 })}
                onClick={() => {
                  onSelected(3);
                }}
              >
                Je préfère la livraison de plats tout prêts sans avoir rien d’autre à faire, même si c’est plus coûteux
              </label>
            </div>
          </div>
        </div>
      </LandingPageContainer>
    );
  };
  
  export default LandingePageIdeal;