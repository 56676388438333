import { useEffect } from "react";
import CommencerCard from "../shared/CommencerCard";
import VosQuestions from "../shared/VosQuestions";

const EquipementBesoin = () => {
  useEffect(() => {
    document.title = 'De quel équipement ai-je besoin ? - Meal Prep Minceur Dr Cohen';
  }, []);

  return (
    <section className="level2-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-8">
            <div className="bcm-leftcol-wrap">
              <h1 className="emerald">
                <strong>De quel équipement ai-je besoin&nbsp;?</strong>
              </h1>
              <p>
                <img src="images/bcm-content-equipement.jpg" alt="équipement ai-je besoin" className="img-fluid" />
              </p>
              <p>Tout préparer à l’avance signifie que vous allez apprendre à </p>
              <p>grouper vos préparations</p> et cela veut aussi dire que vous allez également devoir{" "}
              <strong>optimiser le stockage</strong> de vos préparations.<p></p>
              <p>
                Pour cela, inutile de viser le sur-équipement ou d’investir dans un robot ultra-perfectionné.{" "}
                <strong>Les basiques</strong> de la cuisine suffisent :
              </p>
              <ul>
                <li>1 à 2 casseroles.</li>
                <li>1 à 2 poêles ou sauteuses.</li>
                <li>1 plat à gratin, 1 moule à tarte.</li>
                <li>Un four ou four à micro-ondes avec fonction grill (si possible).</li>
                <li>Petits ustensiles : passoire, cuillère et/ou spatule, couteaux, couteau économe.</li>
                <li>Du papier sulfurisé. </li>
              </ul>
              <p>
                Pour <strong>stocker</strong>, vous aurez besoin de récipients prévoyez&nbsp;:
              </p>
              <ul>
                <li>
                  des boîtes en plastiques ou en verre avec couvercle. Vous pouvez pour les petites portions ou les
                  restes recycler vos bocaux de confiture.
                </li>
                <li>Des bols ou ramequins que vous recouvrez de papier cuisson. </li>
              </ul>
              <p>
                <strong>Bonus</strong>&nbsp;: si vous avez la possibilité d’acheter un lot de boîtes empilables, le
                stockage sera ensuite facilité.
              </p>
              <p>
                Si vous avez un congélateur, vous pouvez réaliser certaines préparations en plus grandes quantités et
                les <strong>congeler</strong>. Là encore, c’est un gain de temps considérable pour les semaines à venir,
                surtout si vous avez un week-end chargé sur lequel vous ne pouvez pas envisager de session Meal Prep
                Minceur. Dans ce cas, pensez à noter sur la boîte le contenu et sa date de préparation.
              </p>
              <p>
                Le Meal Prep Minceur est une formule qui s’adapte à chacun, selon son <strong>budget</strong>, son{" "}
                <strong>lieu</strong> et son <strong>cadre de vie</strong>. C’est votre organisation qui fait toute la
                différence.{" "}
              </p>
              <br />
              <hr />
              <br />
              <VosQuestions />
            </div>
          </div>
          <div className="col-lg-4">
            <CommencerCard />
          </div>
        </div>
      </div>
    </section>
  );
};

export default EquipementBesoin;
