import DietProfileApi from "../api/DietProfileApi";
import BatchCookingAnswers from "../models/BatchCookingAnswers";
import QAnswer from "../models/QAnswer";
import UserProfile from "../models/UserProfile";
import CookieHelper from "../utils/CookieHelper";
import DietUtil from "../utils/DietUtil";

export class CurrentUser {
  //making this public so to have direct access to data
  public static profile: UserProfile;
  public static answers: BatchCookingAnswers;
  
  public static initialize() {
    console.log("DietProfile initialize()");
    try {
      const savedData = localStorage.getItem("userData");
      if (savedData) {
        this.profile = JSON.parse(savedData);
      } else {
        this.profile = {};
      }
      const answerData = localStorage.getItem("answerData");
      if (answerData) {
        this.answers = JSON.parse(answerData);
      } else {
        this.answers = {};
      }
      return true;
    } catch {
      return false;
    }
  }

  public static async setABVersion(dpVersion: number)
  {
    
    switch (dpVersion)
    {
      case 3:
        this.profile.abLandingPage = "cooking-minceur-c";
        break;
      case 2:
        this.profile.abLandingPage = "cooking-minceur";
        break;
      default:
        dpVersion = 2;
        this.profile.abLandingPage = "cooking-minceur";
        break;
    }
    this.saveProfile();
    DietProfileApi.addDietProfileABTest(dpVersion);
    CookieHelper.setABTestLandingPage(dpVersion);
  }

  public static async setAutoABVersion()
  {
    /*
    this.profile.abLandingPage = "cooking-minceur";
    const ALL_GROUPS = ['cooking-minceur', 'cooking-minceur-b']
    type Group = typeof ALL_GROUPS[number]
    const randomGroup = (): Group => ALL_GROUPS[Math.floor(Math.random() * ALL_GROUPS.length)];
    this.profile.abLandingPage = randomGroup.toString();
    */
    /*
    const dpVersion = await DietProfileApi.getDietProfileABTest();
    */
    
    var dpVersion = parseInt(CookieHelper.getABTestLandingPage());

    if (!dpVersion || dpVersion == 0 || dpVersion == 1)
    {
      const min = 2;
      const max = 3;
      dpVersion = Math.round(min + Math.random() * (max - min));
    }
    
    switch (dpVersion)
    {
      case 3:
        this.profile.abLandingPage = "cooking-minceur-c";
        break;
      case 2:
        this.profile.abLandingPage = "cooking-minceur";
        break;
      default:
        dpVersion = 2;
        this.profile.abLandingPage = "cooking-minceur";
        break;
    }
    this.saveProfile();
    DietProfileApi.addDietProfileABTest(dpVersion);
    CookieHelper.setABTestLandingPage(dpVersion);
  }

  public static async addProfile() {
    //if user has unfinished diet profile
    if (this.profile.id && !this.profile.hasDietProfile) return this.profile.id;

    const profileId = await DietProfileApi.addProfile(this.profile.gender ?? 0, this.profile.trackingSid ?? 0, this.profile.trackingV ?? 0, this.profile.dpVersion ?? 0);
    this.profile.id = profileId;
    this.saveProfile();

    CookieHelper.setDietProfile(profileId, this.profile.gender ?? 0);
    return this.profile.id;
  }

  //validate and create user data, save answers
  public static async saveDietProfileAnswers() {
    localStorage.setItem("answerData", JSON.stringify(this.answers));

    if (this.profile.id) {
      const answers: QAnswer[] = [
        { qno: 1, answer: this.answers.q1?.toString() },
        { qno: 2, answer: this.answers.q2?.toString() },
        { qno: 3, answer: this.answers.q3?.toString() },
        { qno: 4, answer: this.answers.q4?.toString() },
        { qno: 5, answer: this.answers.q5?.toString() },
        { qno: 6, answer: this.answers.q6?.toString() },
        { qno: 7, answer: this.answers.q7?.toString() },
        { qno: 8, answer: this.answers.q8?.join(",").toString() },
        { qno: 9, answer: this.answers.q9?.toString() },
        { qno: 10, answer: this.answers.q10?.toString() },
        { qno: 11, answer: this.answers.q11?.toString() },
      ];

      await DietProfileApi.saveAnswers(this.profile.id, answers);
      return true;
    }
    return false;
  }

  //validate and create user data, save answers
  public static async updateProfile() {
    this.saveProfile();
    if (this.profile.id) {
      const profileId = await DietProfileApi.updateProfile(CurrentUser.profile);
      this.profile.hasDietProfile = true;
      this.saveProfile();
      CookieHelper.setABTestLandingPage(0);
      return profileId;
    }
    return 0;
  }

  public static saveProfile() {
    localStorage.setItem("userData", JSON.stringify(this.profile));
  }

  public static clearProfile() {
    //clear only if user hasnt finished diet profile
    if (this.profile.hasDietProfile) {
      this.profile = {};
    }
  }

  // public static saveAnswersData() {
  //   localStorage.setItem("answerData", JSON.stringify(this.answers));
  // }

  public static clearAnswers() {
    this.answers = {};
  }

  public static get hasDietProfileResults() {
    if (
      this.profile.firstName &&
      this.profile.surName &&
      this.profile.gender !== undefined &&
      this.profile.weight &&
      this.profile.height &&
      this.profile.targetWeight
    )
      return true;
    return false;
  }

  public static get targetWeightloss() {
    if (this.profile.weight && this.profile.targetWeight) {
      return this.profile.weight - this.profile.targetWeight;
    }
    return 10;
  }

  //in months
  public static get weightlossDuration() {
    return Math.floor(this.targetWeightloss / 4 + 0.6);
  }

  public static get BMI() {
    return DietUtil.BMI(this.profile.height ?? 0, this.profile.weight ?? 0);
  }

  public static get genderName() {
    return this.profile.gender === 1 ? "male" : "female";
  }
}
