import { Link } from "react-router-dom";
import VosQuestions from "../shared/VosQuestions";
import { Testimonials } from "./testimonials";

const Home = () => {
  return (
    <>
      <section className="mainBlockBatchCooking">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="bcm-main-content">
                                <h1 className="gray3">Comment <span className="emerald">maigrir</span> en y consacrant seulement <u style={{textDecorationColor:"#25bf2d"}}>2 h par semaine</u>&nbsp;?</h1>
                                <ul className="bcm-main-list">
                                    <li>
                                        <div className="check">
                                            <i className="fal fa-check"></i>
                                        </div>
                                        <div className="lc">
                                            <h5 className="gray3">Gagnez du temps</h5>
                                            <p>Préparez tous les repas de votre semaine en 2 heures</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="check">
                                            <i className="fal fa-check"></i>
                                        </div>
                                        <div className="lc">
                                            <h5 className="gray3">Faites des économies</h5>
                                            <p>Cuisinez en lot pour acheter juste ce qu’il faut et ne plus rien gaspiller</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="check">
                                            <i className="fal fa-check"></i>
                                        </div>
                                        <div className="lc">
                                            <h5 className="gray3">Devenez autonome</h5>
                                            <p>Apprenez à cuisiner vous-même les bons petits plats qui vont vous faire maigrir</p>
                                        </div>
                                    </li>
                                </ul>
                                <p>Jean-Michel Cohen vous apprend à manger équilibré pour perdre du poids et rester mince.</p>
                                <div className="text-center">
                                    <a className="cta-salmon" href="/cooking-minceur">COMMENCER MAINTENANT</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 bcm-jmc-img">
                            <div className="text-center"><img alt="Jean-Michel COHEN" className="img-fluid" src="images/bcm-jmc-transparent.png"/></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bcm-features-section">
                <div className="container-fluid">
                    <h2 className="text-center emerald"><strong>Maigrir sans les contraintes du régime&nbsp;!</strong></h2>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="bcm-features">
                                <div className="feature-img"><img alt="Des menus équilibrés et des plats faits maison" className="img-fluid mx-auto" src="https://mealprep.jeanmichelcohen.fr/images/bcm-features-cooking.png"/></div>
                                <div className="feature-content">
                                    <h4>Des menus équilibrés et des plats faits maison</h4>
                                    <p>Pas de plats industriels, pas de paquets de chips, pas de barquettes sorties du congélateur. Juste des recettes simples, savoureuses et diététiques concoctées par le nutritionniste Jean-Michel Cohen.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="bcm-features">
                                <div className="feature-img d-block d-md-none"><img alt="Le Meal Prep pour gagner du temps et de l’argent" className="img-fluid mx-auto" src="https://mealprep.jeanmichelcohen.fr/images/bcm-features-grocery.png"/></div>
                                <div className="feature-content">
                                    <h4>Le Meal Prep pour gagner du temps et de l’argent</h4>
                                    <p>Vous ne faites plus les courses qu'une seule fois par semaine et vous faites des économies. En gérant mieux les quantités, en recyclant les restes, vous ne jetez plus rien et vous gaspillez moins.</p>
                                </div>
                                <div className="feature-img d-none d-md-block"><img alt="Le Meal Prep pour gagner du temps et de l’argent" className="img-fluid mx-auto" src="https://mealprep.jeanmichelcohen.fr/images/bcm-features-grocery.png"/></div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="bcm-features">
                                <div className="feature-img d-block d-md-none"><img alt="Une meilleure organisation, c’est plus de temps pour vous" className="img-fluid mx-auto" src="https://mealprep.jeanmichelcohen.fr/images/bcm-features-bathing.png"/></div>
                                <div className="feature-content">
                                    <h4>Une meilleure organisation, c’est plus de temps pour vous</h4>
                                    <p>Vous pouvez consacrer tout le temps que vous gagnez à votre famille ou pour vous occuper de vous. Plus besoin de vous demander ce que vous allez manger... C’est déjà prêt, il suffit de réchauffer&nbsp;!</p>
                                </div>
                                <div className="feature-img d-none d-md-block"><img alt="Une meilleure organisation, c’est plus de temps pour vous" className="img-fluid mx-auto" src="https://mealprep.jeanmichelcohen.fr/images/bcm-features-bathing.png"/></div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="bcm-features">
                                <div className="feature-img"><img alt="Une formule unique à tout petit prix" className="img-fluid mx-auto" src="https://mealprep.jeanmichelcohen.fr/images/bcm-features-smiling.png"/></div>
                                <div className="feature-content">
                                    <h4>Une formule unique à tout petit prix</h4>
                                    <p>Vous remboursez votre abonnement en moins de 2 semaines grâce aux économies que nous vous permettons de réaliser ! C’est le double bénéfice d’une meilleure organisation et de la cuisine par lot&nbsp;!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bcm-icons-section">
                <div className="container-fluid">
                    <div className="bcm-icons-flex">
                        <div className="bcm-icon">
                            <img alt="+ rapide" src="https://mealprep.jeanmichelcohen.fr/images/bcm-icon-rapide.png"/>
                            <h5>+ rapide</h5>
                            <div>
                                je gagne du temps
                            </div>
                        </div>
                        <div className="bcm-icon">
                            <img alt="+ pratique" src="https://mealprep.jeanmichelcohen.fr/images/bcm-icon-pratique.png"/>
                            <h5>+ pratique</h5>
                            <div>
                                je suis mieux organisée
                            </div>
                        </div>
                        <div className="bcm-icon">
                            <img alt="+ économique " src="https://mealprep.jeanmichelcohen.fr/images/bcm-icon-economique.png"/>
                            <h5>+ économique</h5>
                            <div>
                                je fais des économies
                            </div>
                        </div>
                        <div className="bcm-icon">
                            <img alt="+ pédagogique " src="https://mealprep.jeanmichelcohen.fr/images/bcm-icon-pedagogique.png"/>
                            <h5>+ pédagogique</h5>
                            <div>
                                j’apprends à cuisiner
                            </div>
                        </div>
                        <div className="bcm-icon">
                            <img alt="+ gratifiant" src="https://mealprep.jeanmichelcohen.fr/images/bcm-icon-gratifiant.png"/>
                            <h5>+ gratifiant</h5>
                            <div>
                                je maigris en toute autonomie
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="batchCooking-details">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 col-md-5"></div>
                        <div className="col-lg-6 col-md-7">
                            <div className="bcm-dual-content">
                                <h2 className="emerald"><strong>Comment ça marche&nbsp;?</strong></h2>
                                <h4 className="gray3"><strong>Le Meal Prep Minceur Dr Cohen, c’est chaque semaine&nbsp;:</strong></h4>
                                <div className="mx-auto my-3 d-block d-md-none">
                                    <div style={{marginLeft: "-30px", marginRight: "-30px"}}><img alt="" className="img-fluid mx-auto" src="https://mealprep.jeanmichelcohen.fr/images/bcm-assortedfood-inset.jpg"/></div>
                                </div>
                                <ul className="bcm-main-list">
                                    <li>
                                        <div className="check emerald">
                                            <i className="fal fa-check"></i>
                                        </div>
                                        <div className="lc">
                                            <p>5 plats minceur à cuisiner vous-même en deux heures</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="check emerald">
                                            <i className="fal fa-check"></i>
                                        </div>
                                        <div className="lc">
                                            <p>Une liste de courses optimisée avec 25 produits maximum à acheter en une seule fois</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="check emerald">
                                            <i className="fal fa-check"></i>
                                        </div>
                                        <div className="lc">
                                            <p>Un budget alimentation contrôlé, très appréciable en période de forte inflation</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="check emerald">
                                            <i className="fal fa-check"></i>
                                        </div>
                                        <div className="lc">
                                            <p>Un atelier vidéo meal prep live pour poser toutes vos questions aux experts</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="check emerald">
                                            <i className="fal fa-check"></i>
                                        </div>
                                        <div className="lc">
                                            <p>La garantie de perdre du poids à votre rythme en cuisinant vous-même</p>
                                        </div>
                                    </li>
                                </ul>
                                <p>Chaque lundi, vous recevez votre menu Meal Prep Minceur correspondant à la semaine suivante. Vous avez ainsi tout le temps pour vous organiser pour les courses et la séance de cuisine de 2 heures.</p>
                                <div className="text-center">
                                    <a className="cta-salmon" href="/page-de-paiement.asp">COMBIEN CA COUTE&nbsp;?</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-gray">
                <div className="container-fluid">
                    <h2 className="gray3 text-center"><strong>Suivez Jean-Michel Cohen sur les réseaux</strong></h2>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="social text-center">
                                <h3><img alt="YouTube" className="img-fluid mx-auto" src="https://savoir-maigrir.aujourdhui.com/img_aj/drcohenfr/sm22-title-youtube.png"/></h3>
                                <p><strong className="gray3">La chaine à + de 700 000 abonnés</strong></p>
                                <p>Des nouvelles videos toutes les semaines avec Jean-Michel Cohen sur sa chaine nutrition #1 en France.</p>
                                <div className="ratio ratio-16x9">
                                <iframe
                                  title="Perdre du poids avec le Dr Jean-Michel Cohen"
                                  className="ratio-item"
                                  src="https://www.youtube.com/embed/pSF2qRsGXtI?rel=0"
                                  allowFullScreen={true}
                                ></iframe>
                                    
                                </div>
                                <p className="videoLegend">Perdre du poids avec le Dr Jean-Michel Cohen</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="social text-center">
                                <h3><img alt="Facebook" className="img-fluid mx-auto" src="https://savoir-maigrir.aujourdhui.com/img_aj/drcohenfr/sm22-title-facebook.png"/></h3>
                                <p><strong className="gray3">La page à + de 100,000 abonnés</strong></p>
                                <p>Des nouveaux posts tous les jours pour suivre l’actualité de la nutrition et du Dr. Jean-Michel Cohen.</p>
                                <div>
                                    <a href="https://www.facebook.com/regimecohen" rel="noreferrer" target="_blank"><img alt="Dr Jean-Michel Cohen" className="img-fluid" src="https://i.ytimg.com/vi/8wUgBZii-2Y/maxresdefault.jpg"/></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="social text-center">
                                <h3><img alt="Blog du coach" className="img-fluid mx-auto" src="https://savoir-maigrir.aujourdhui.com/img_aj/drcohenfr/sm22-title-blogducoach.png"/></h3>
                                <p>
                                    <strong className="ellipsis gray3">10 conseils pour mincir à petit prix !</strong><br/>
                                    <em className="smaller">Posté le 20 septembre 2022</em>
                                </p>
                                <p>Vous souhaitez conserver une alimentation saine...</p>
                                <div className="ratio ratio-16x9">
                                    <a className="ratio-item" href="/meal-prep-cest-quoi" target="_blank" title="Lire le blog du coach"><img alt="Blog du coach" className="img-fluid" src="https://img.aujourdhui.com/coachblog/conseils-pour-maigrir-pas-cher.jpg"/></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

      <section className="bcm-testimonials-section">
        <Testimonials />
      </section>

      <section className="bg-gray">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <VosQuestions />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
