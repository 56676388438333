import React from "react";
import { Fade } from "reactstrap";
import styled, { keyframes } from "styled-components";

interface Props {
  children: React.ReactNode;
}

const LandingPageContainerB = ({ children }: Props) => {
  return <FadeContainer in={true}>{children}</FadeContainer>;
};

export default LandingPageContainerB;

const FadeIn = keyframes`
    0% {
       opacity: 0;
       display: block;
    }
    100%{
        opacity: 1.0;
    }
`;

const FadeContainer = styled(Fade)`
  &.fade {
    opacity: 0;
    display: none;
  }
  &.show {
    opacity: 1;
    display: block;
    animation: ${FadeIn} 2s;
  }
`;
