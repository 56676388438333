import { useEffect } from "react";
import CommencerCard from "../shared/CommencerCard";
import VosQuestions from "../shared/VosQuestions";

const ConserverPlatsSemaine = () => {
  useEffect(() => {
    document.title = 'Comment bien conserver mes plats de la semaine ? - Meal Prep Minceur Dr Cohen';
  }, []);

  return (
    <section className="level2-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-8">
            <div className="bcm-leftcol-wrap">
              <h1 className="emerald">
                <strong>Comment bien conserver mes plats de la semaine&nbsp;?</strong>
              </h1>
              <p>
                <img
                  src="images/bcm-content-conserver-plats-semaine.jpg"
                  alt="équipement ai-je besoin"
                  className="img-fluid"
                />
              </p>
              <p>
                Pendant votre séance de cuisine, vous allez commencer à organiser le stockage de vos plats cuisinés et
                des préparations culinaires. Parfait. Tout se met en place.
              </p>
              <p>
                Tupperware, film plastique, papier aluminium, congélation… selon la nature de vos préparations,
                l’équipement dont vous disposez et les repas que vous avez préparés, vous allez devoir stocker le tout.
              </p>
              <br />
              <h2 className="salmon">
                <strong>Frigo ou congélateur ?</strong>
              </h2>
              <p>
                Les préparations qui se gardent <strong>plus longtemps</strong> et que vous avez prévues en fin de
                semaine iront au <strong>fond du frigo</strong>. Les préparations de <strong>début de semaine</strong>{" "}
                et celles qui servent plusieurs fois dans la semaine seront, quant à elles, tout de suite{" "}
                <strong>accessibles</strong>.
              </p>

              <p>
                La <strong>congélation</strong> est aussi envisageable si vous avez un congélateur (ou dans la partie
                freezer de votre réfrigérateur, ce qui peut suffire pour trois jours de stockage).
              </p>
              <p>
                Tout est question d’organisation, de l’élaboration de vos plans de repas au rangement de votre frigo. Le
                Meal Prep Minceur Dr Cohen va vous simplifier la vie au quotidien
              </p>

              <br />
              <hr />
              <br />
              <VosQuestions />
            </div>
          </div>
          <div className="col-lg-4">
            <CommencerCard />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ConserverPlatsSemaine;
