const LocalConstants = {
    API_URL: 'https://localhost:7121/',
};

const LiveConstants = {
    API_URL: 'https://mealprep.jeanmichelcohen.fr/api',
};

const REACT_APP_ENVIRONMENT = `${process.env.REACT_APP_ENVIRONMENT}`;
const Constants = REACT_APP_ENVIRONMENT === 'local' ? { ...LocalConstants } : { ...LiveConstants };
export default Constants;
