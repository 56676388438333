import { useEffect } from "react";
import CommencerCard from "../shared/CommencerCard";
import VosQuestions from "../shared/VosQuestions";

const CommentSePasse = () => {
  useEffect(() => {
    document.title = '1 Semaine de Meal Prep : comment ça se passe ? - Meal Prep Minceur Dr Cohen';
  }, []);

  return (
    <section className="level2-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-8">
            <div className="bcm-leftcol-wrap">
              <h1 className="emerald">
                <strong>1 Semaine de Meal Prep : comment ça se passe&nbsp;?</strong>
              </h1>
              <p>
                <img src="images/bcm-content-comment-se-passe.jpg" alt="comment ça se passe" className="img-fluid" />
              </p>

              <p>
                Préparer tous les repas de la semaine demande avant tout de <strong>l’organisation</strong>. Voici
                quelques conseils pour vous guider et vous faciliter la tâche.
              </p>
              <br />
              <h2 className="salmon">
                <strong>L’organisation de départ&nbsp;: plans de repas et liste de courses</strong>
              </h2>
              <p>“Qu’est-ce qu’on mange ce soir ?” C’est la question qui revient le plus, n’est-ce pas&nbsp;?</p>
              <p>
                Le Meal Prep Minceur du Dr Cohen vous propose des plans de repas pour{" "}
                <strong>toute la semaine suivante</strong>, du lundi au dimanche, ainsi que{" "}
                <strong>la liste de courses correspondante</strong>. Vous avez ainsi le temps d'organiser vos différents
                achats dans la semaine pour vous préparer à cuisiner le week-end. Samedi ou dimanche, matin ou
                après-midi, c’est vous qui voyez.
              </p>
              <p>
                Nous vous proposons systématiquement <strong>cinq recettes originales</strong> par semaine, avec des
                produits et des préparations à réutiliser dans plusieurs plats, pour réduire le nombre d’ingrédients à
                acheter et limiter le temps en cuisine à 2 heures environ. Oui, <strong>2 heures</strong> aux fourneaux
                pour tous les repas de la semaine. C’est non seulement possible, mais c’est aussi{" "}
                <strong>notre engagement</strong>&nbsp;!
              </p>
              <p>
                Notre meal prep est innovant car basé sur 7 jours et 3 repas par jour, il inclut ainsi un déjeuner à
                emporter par exemple, afin d’éviter le recours à des plats préparés industriels, sandwich ou fast food…
                économies à la clé !
              </p>

              <br />
              <h2 className="salmon">
                <strong>La session de cuisine et le stockage</strong>
              </h2>
              <p>
                Vos courses sont faites et rangées et vous vous préparez à débuter cette session cuisine. C’est la
                partie qui demande le plus d’organisation mais rassurez-vous, nous avons tout prévu et grâce à la fiche{" "}
                <strong>“On cuisine quoi cette semaine ?”</strong>, préparer votre semaine devient un jeu d’enfant !
              </p>
              <p>
                <strong>Prenez le temps de lire cette page</strong> en entier avant de vous lancer. Sortez les
                ingrédients et ustensiles dont vous aurez besoin et il n’y a plus qu’à vous laisser guider. Réveillez le
                chef de cuisine qui est en vous et vous verrez que tout est optimisé entre temps de préparation et de
                cuisson
              </p>
              <p>
                Il ne vous restera plus qu’à stocker pour la semaine de façon pratique selon le plan de repas qui est
                établi.
              </p>

              <br />
              <h2 className="salmon">
                <strong>La surprise de la semaine</strong>
              </h2>
              <p>
                Vous avez tout cuisiné à l’avance, vos préparations sont prêtes et stockées : c’est parti pour la
                semaine, sans se poser de question. Quoi de mieux pour libérer votre charge mentale&nbsp;!
              </p>
              <p>
                Il ne vous reste plus qu’à sortir vos plats cuisinés, les réchauffer et les compléter selon le plan avec
                un assaisonnement, quelques préparations minute et en <strong>10 minutes</strong>, tout est sur la
                table. Fabuleux&nbsp;!
              </p>
              <p>
                Tenez-vous-en à vos plans de repas, mangez de tout avec appétit et régalez-vous à chaque fois que vous
                prenez place à table&nbsp;
              </p>

              <br />
              <hr />
              <br />
              <VosQuestions />
            </div>
          </div>
          <div className="col-lg-4">
            <CommencerCard />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CommentSePasse;
