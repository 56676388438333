export default class StringUtil {
    // public static ToInteger = (txt: string | undefined | null, defaultValue: number): number => {
    //     const n = parseInt(txt ?? '');
    //     return isNaN(n) ? defaultValue : n;
    // };

    public static ToNumeric = (txt: string, defaultValue: number = 0): number => {
        const n = parseFloat(txt ?? '');
        return isNaN(n) ? defaultValue : n;
    };


    public static GetNumeric = (txt: string): number | null => {
        if (!txt) return null;
        let value = txt.replace(',', '.');
        if (value.endsWith(".")) {
            value = value.slice(0, -1);
        }
        if (isNaN(parseFloat(value)) || isNaN(Number(value))) return null;
        return Number(value);
    };

    // public static GetNumericOrZero = (txt?: string): number => {
    //     const n = this.GetNumeric(txt);
    //     return n !== null ? n : 0;
    // };

    public static isNumeric = (txt: string): boolean => {
        return this.GetNumeric('0' + txt) !== null;
    };

    public static isValidEmail = (email: string): boolean => {
        const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        return regex.test(email);
    };
       
    // public static To1or0 = (txt: string | undefined | null): number | undefined => {
    //     if (!txt) return undefined;
    //     if (txt === '0') return 0;

    //     return 1;
    // };

    public static Ellipsis = (txt: string): string => {
        return txt.length > 150 ? txt.substring(0, txt.indexOf(' ', 150)) + ' ...' : txt;
    };
}
