import ApiBase from "./ApiBase";
import ContactMessage from "../models/ContactMessage";

export default class ContactApi extends ApiBase {
    
    public static AddMessage = async (model: ContactMessage): Promise<any> =>  {
        try{
            var x = this.post("https://mealprep.jeanmichelcohen.fr/api/contact", model);
            return true;
         } catch(e) { console.error(e); }
         return false;
    };
}