import Layout from "./Layout";

function PageNotFound() {
  return (
    <Layout>
      <section>
        <h2>Hoooooo !!!</h2>
        <p>Désolé, page introuvable</p>
      </section>
    </Layout>
  );
}

export default PageNotFound;
