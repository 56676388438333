export enum LandingPagePathC {
    Start = '/cooking-minceur-c',
    Page2 = '/cooking-minceur-c/p2',
    Page3 = '/cooking-minceur-c/p3',
    Page4 = '/cooking-minceur-c/p4',
    Page5 = '/cooking-minceur-c/p5',
    Page6 = '/cooking-minceur-c/p6',
    Page7 = '/cooking-minceur-c/p7',
    Page8 = '/cooking-minceur-c/p8',
    Page9 = '/cooking-minceur-c/p9',
    Page10 = '/cooking-minceur-c/p10',
    Page11 = '/cooking-minceur-c/p11',
    UserDetails = '/cooking-minceur-c/details',
    DietGraph =  '/cooking-minceur-c/graph',
    LoaderForm = '/cooking-minceur-c/loading',
    Results = '/cooking-minceur-c/results',
}