import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import LandingPageContainerC from "./LandingPageContainerC";
import { LandingPagePathC } from ".";
import { CurrentUser } from "../../controllers/CurrentUser";


const LandingPageCp2 = () => {
    const [answer, setAnswer] = useState<number | undefined>(CurrentUser.answers.q2);
    const scrollRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();

    //window.scrollTo(0,800);
    useEffect(() => {
      setTimeout(() => {
        scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 500);
    }, []);
    
    const onSelected = (value: number) => {
      setAnswer(value);
      CurrentUser.answers.q2 = value;
    };

    const onContinue = () => {
      CurrentUser.answers.q2 = answer;
  
      setTimeout(() => {
        navigate(LandingPagePathC.Page3);
      }, 500);
    };
  
    return (
      <LandingPageContainerC>
        <div className="dpq-question" id="bcm-q2" data-progress="2" style={{display:"block"}}>
          <h5 className="text-center gray3 my-3" ref={scrollRef}>La perte de poids demande beaucoup de temps de préparation, au moins une heure de cuisine par jour&nbsp;? </h5>
          
          <div className="quickquiz text-center">
              <label className={classNames({ selected: answer === 1 })}
                onClick={() => {
                  onSelected(1);
                }}>
                  <input type="radio" name="bcm-q2" value="1" onClick={() => {
                    onSelected(1);
                  }}/>
                  <strong>VRAI</strong> 
              </label>

              <label className={classNames({ selected: answer === 0 })}
                onClick={() => {
                  onSelected(0);
                }}>
                  <input type="radio" name="bcm-q2" value="0" onClick={() => {
                    onSelected(0);
                  }}/>
                  <strong>FAUX</strong>
              </label>
          </div>
          {answer=== 1 && (
          <div className="quizResult yes" style={{display:"block"}}>
              <h5 className="salmon"><strong>C'est faux !</strong></h5>
              <div>Le principe du Meal Prep Minceur consiste à préparer vos repas à l'avance, en 2 h le weekend, pour les sept jours de la semaine suivante. C'est un gain de temps considérable pour vous&nbsp;!</div>
          </div>
          )}
          {answer === 0 && (
          <div className="quizResult no" style={{display:"block"}}>
              <h5 className="salmon"><strong>Bien vu !</strong></h5>
              <div>Le principe du Meal Prep Minceur consiste à préparer vos repas à l'avance, en 2 h le weekend, pour les sept jours de la semaine suivante. C'est un gain de temps considérable pour vous&nbsp;!</div>
          </div>
          )}  
          <br />
          {answer !== undefined && (  
          <div className="text-center continueQuiz">
              <button type="button" className="cta-salmon" onClick={onContinue}>Continuer</button>
          </div>)}
      </div>
      </LandingPageContainerC>
    );
  };
  
  export default LandingPageCp2;