import CommencerCard from "../shared/CommencerCard";
import { ContactController } from "../../controllers/ContactController";
import { useState } from "react";
import StringUtil from "../../utils/StringUtil";


interface InValidFormState {
  contactName?: string;
  email?: string;
  reason?: string;
  message?: string;
  homeNumber?: string;
  mobileNumber?: string;
}

const initialFormState: InValidFormState = {};

const Contact = () => {

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [contactName, setContactName] = useState("");
  const [email, setEmail] = useState("");
  const [reason, setReason] = useState("");
  const [message, setMessage] = useState("");
  const [homeNumber, sethomeNumber] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");

  const [inValidState, setInValidState] = useState<InValidFormState>(initialFormState);

  ContactController.initialize();
  //setShowConfirmation(false);
  const submitMessage = async () => {
      let _state = initialFormState;
      if (!contactName) {
        _state = { ..._state, contactName: "Merci de préciser votre prénom" };
      }
      
      if (!email || !StringUtil.isValidEmail(email)) {
        _state = { ..._state, email: "Merci de préciser votre email" };
      }

      if (!reason) {
        _state = { ..._state, reason: "Merci de préciser la raison de votre Email" };
      }

      if (!message) {
        _state = { ..._state, message: "Merci de préciser le contenu de votre Email" };
      }

      if (homeNumber && !homeNumber.match('[0-9]')) {
        _state = {
          ..._state,
          homeNumber:
            "Attention, votre numéro de téléphone ne semble pas être correct, merci de vérifier",
        };
      }
  

      if (mobileNumber && !mobileNumber.match('[0-9]')) {
        _state = {
          ..._state,
          mobileNumber:
            "Attention, votre numéro de téléphone ne semble pas être correct, merci de vérifier",
        };
      }
  
      setInValidState(_state);
      //console.log(_state);
      if (_state !== initialFormState) {
        return;
      }
  
      ContactController.message.contactName = contactName;
      ContactController.message.email = email;
      ContactController.message.telephone = homeNumber;
      ContactController.message.portable = mobileNumber;
      ContactController.message.reason = reason;
      ContactController.message.message = message;
  
      await ContactController.addMessage();
  
      setTimeout(() => {
        setShowConfirmation(true);
      }, 500);
    };  

    return (
      <section className="level2-content">
        <div className="container-fluid">
        {showConfirmation && (
          <div className="row">
            <div className="col-lg-8">
          <div className="bcm-leftcol-wrap">
              <h1 className="emerald"> <strong>Contactez-nous</strong></h1>
              <p>Votre message a bien &#233;t&#233; envoy&#233; &#224; notre Service Consommateur. Nos &#233;quipes accordent la plus grande attention &#224; vos remarques, critiques et suggestions. Nous nous effor&#231;ons de vous r&#233;pondre au plus vite.</p>
              <p>Notre réponse vous parviendra à l'adresse suivante :  <strong>{email}</strong><br/>
              Merci de votre confiance.</p>



              <p>Jean-Michel Berille, Responsable du service consommateur.<br /></p>
              <p><a href="/">Retour à la page d'accueil</a></p>
              <p><em>*Ouvert de 9h00 à 18h du lundi au vendredi, prix d’un appel local depuis un poste fixe.</em></p>
           </div>
          <br />
          <hr />  <br />
            </div>
            <div className="col-lg-4">
              <CommencerCard />
            </div>
          </div>  
        )}
        {!showConfirmation && (
            <div className="row">
                <div className="col-lg-12">
                    <div className="bcm-leftcol-wrap">
                        <h1 className="emerald"> <strong>Contactez-nous</strong></h1>
                        <p>Vous souhaitez en savoir plus sur le programme Meal Prep Minceur ? Son fonctionnement, ce que vous allez manger, les tarifs… Appelez maintenant nos agents au téléphone au <a href="tel:+33411880112">04 11 88 01 12</a>* ou remplissez le formulaire ci-dessous, nous nous engageons à vous répondre le plus rapidement possible.</p>
                        <p> <strong>L'équipe Meal Prep Minceur</strong> </p>
                        <div className="alert alert-info">À savoir : Avez-vous pensé à consulter  <a href="/faq">notre page d&#39;aide - FAQ</a> ? Bien que notre service client vous réponde sous 24h, sachez que 85% des questions y trouvent leur réponse en quelques minutes !</div>
                        <p><em>*Ouvert de 9h00 à 18h du lundi au vendredi, prix d’un appel local depuis un poste fixe.</em></p>
                     </div>
                    <br />
                    <hr />  <br />
                    <div className="row">
                        <div className="col-md-5"><p className="plab"><label>Votre prénom:</label></p></div>
                        <div className="col-md-5">
                            <p>
                                <input className="form-control" id="Prenom" maxLength={50} size={40} type="text" onChange={(e) => setContactName(e.currentTarget.value)} />
                                {inValidState.contactName && (
                                  <div className="bcmdp-errorMsg">{inValidState.contactName}</div>
                                )}
                            </p>
                        </div>
                    </div>

                    <div className="row">

                        <div className="col-md-5"><p className="plab"><label>Votre E-mail:</label></p></div>
                        <div className="col-md-5">
                            <p>
                                <input className="form-control"  id="Email" maxLength={50} size={40} type="email" onChange={(e) => setEmail(e.currentTarget.value)}/>
                                {inValidState.email && (
                                  <div className="bcmdp-errorMsg">{inValidState.email}</div>
                                )}
                            </p>
                        </div>
                    </div>

                    <div className="row">

                        <div className="col-md-5"><p className="plab"><label>Votre numéro de téléphone fixe: </label></p></div>
                        <div className="col-md-5">
                            <p>
                                <input className="form-control" id="Phone" maxLength={20} size={40} type="text" onChange={(e) => sethomeNumber(e.currentTarget.value)}/>
                                {inValidState.homeNumber && (
                                  <div className="bcmdp-errorMsg">{inValidState.homeNumber}</div>
                                )}
                            </p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-5"><p className="plab"><label>Votre numéro de téléphone portable:<br/><span style={{color:"rgb(153, 153, 153)", fontWeight:"normal"}}>(pour vous contacter rapidement)</span></label></p></div>
                        <div className="col-md-5">
                            <p>
                                <input className="form-control" id="Mobile" maxLength={20} size={40} type="text" onChange={(e) => setMobileNumber(e.currentTarget.value)}/>
                                {inValidState.mobileNumber && (
                                  <div className="bcmdp-errorMsg">{inValidState.mobileNumber}</div>
                                )}
                            </p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-5"><p className="plab"><label>Raison de votre Email:</label></p></div>
                        <div className="col-md-5">
                            <p>
                                <input className="required form-control" id="Titre" maxLength={50}  size={40} type="text" onChange={(e) => setReason(e.currentTarget.value)}/>
                                {inValidState.reason && (
                                  <div className="bcmdp-errorMsg">{inValidState.reason}</div>
                                )}
                            </p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-5"><p className="plab"><label>Description:</label></p></div>
                        <div className="col-md-5">
                            <p>
                                <textarea className="required form-control" cols={35} id="Probleme" name="Probleme" rows={10} onChange={(e) => setMessage(e.currentTarget.value)}></textarea>
                                {inValidState.message && (
                                  <div className="bcmdp-errorMsg">{inValidState.message}</div>
                                )}
                            </p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <p className="text-center">
                            
                                <button type="submit" className="cta-salmon" onClick={submitMessage}>VALIDER</button>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            )}
        </div>
    </section>
    );
  };
  
  export default Contact;
  