import { useEffect } from "react";
import CommencerCard from "../shared/CommencerCard";
import VosQuestions from "../shared/VosQuestions";

const BatchCooking = () => {
  useEffect(() => {
    document.title = 'Le Meal Prep Minceur Dr Cohen, c\'est quoi ? - Meal Prep Minceur Dr Cohen';
  }, []);
  
  return (
    <section className="level2-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-8">
            <div className="bcm-leftcol-wrap">
              <h1 className="emerald">
                <strong>
                  Le Meal Prep Minceur Dr&nbsp;Cohen, c'est quoi&nbsp;?
                </strong>
              </h1>
              <p>
                <img
                  src="images/bcm-content-bcm-cest-quoi.jpg"
                  alt="Cest quoi"
                  className="img-fluid"
                />
              </p>

              <p>
                Le Meal Prep, c’est tout simplement le fait de préparer ses
                repas en avance, en une seule fois, pour les trois jours
                suivants ou bien pour la semaine entière. C’est une méthode qui
                vous permet, avec un peu d’organisation, de manger varié et
                équilibré, à petits prix et de diminuer votre charge
                mentale&nbsp;!
              </p>
              <p>
                En une seule session, avec nous, vous cuisinerez en justes
                quantités vos plats pour les jours à venir et les préparations
                nécessaires à vos plans de repas : vous{" "}
                <strong>gagnez du temps</strong>, et il n’y a{" "}
                <strong>aucun gaspillage</strong>.
              </p>
              <p>
                Si le Meal Prep demande un peu d’
                <strong>organisation</strong>, il évite le fameux{" "}
                <em>“Qu’est-ce qu’on mange ce soir ?”</em>, qui se traduit
                souvent par des repas faits à la hâte n’optimisant ni le contenu
                de votre réfrigérateur et de vos placards, ni votre budget.
              </p>

              <p>
                Avec le Meal Prep Minceur, vos{" "}
                <strong>plans de repas</strong> sont{" "}
                <strong>établis en avance</strong>. Vous n’avez plus qu’à faire{" "}
                <strong>vos courses</strong> en suivant{" "}
                <strong>la liste préétablie</strong>, puis à vous mettre en
                cuisine <strong>1 seule fois dans la semaine</strong>&nbsp;:
                cette session de 2 à 3 heures vous suffira pour préparer vos
                plats et recettes <strong>des 7 prochains jours</strong>.{" "}
              </p>
              <p>
                Moins de temps passé à réfléchir à vos menus ou à cuisiner
                chaque jour, moins de vaisselle au quotidien, ce qui assurera un
                véritable gain de temps et d'énergie !
              </p>
              <p>
                Vos plats cuisinés seront conservés au réfrigérateur et/ou
                congélateur jusqu’au jour de leur consommation ; le jour j, il
                vous suffira de les réchauffer et au besoin, finaliser certaines
                préparations (assembler légumes et féculents, cuire des œufs…).
              </p>
              <p>
                Vous souhaitez perdre du poids, en mangeant équilibré et en
                contrôlant votre budget, sans pour autant passer des heures en
                cuisine ? C’est sûr, le <strong>Meal Prep</strong> Minceur
                est fait pour vous !
              </p>

              <br />
              <hr />
              <br />
              <VosQuestions />
            </div>
          </div>
          <div className="col-lg-4">
            <CommencerCard />
          </div>
        </div>
      </div>
    </section>
  );
};

export default BatchCooking;
