import { Modal, ModalBody } from "reactstrap";
import { useAppSelector } from "../../store/hooks";
import { PulseLoader } from "./PulseLoader";

export const AppLoadingIndicator = () => {
  const loading = useAppSelector((state) => state.loading);

  return (
    <Modal isOpen={loading.show} style={{ zIndex: 1061 }}>
      <ModalBody>
        <PulseLoader />
      </ModalBody>
    </Modal>
  );
};
