import { Link } from "react-router-dom";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import "./bcm-dietprofile.css";

const DietProfileHeaderNoLink = () => {
  return (
    <header id="BcmDPHeader">
      <div className="container-fluid">
        <div className="headPad">
          <a href="#" className="text-center bcm-header-logo">
            <img
              src="/images/bcm-header-logo-d-v3.png"
              alt="Meal Prep Minceur COHEN"
              className="img-fluid d-none d-sm-block mx-auto"
            />
            <img
              src="/images/bcm-header-logo-d-v3.png"
              alt="Meal Prep Minceur COHEN"
              className="img-fluid d-block d-sm-none mx-auto"
            />
          </a>

          
        </div>
      </div>
    </header>
  );
};

export default DietProfileHeaderNoLink;
