export enum LandingPagePath {
    Start = '/cooking-minceur-old',
    SelectIdeal = '/cooking-minceur-old/ideal',
    UserDetails = '/cooking-minceur-old/details',
    DietGraph =  '/cooking-minceur-old/graph',
    Figure = '/cooking-minceur-old/figure',
    Exces = '/cooking-minceur-old/exces',
    Activity = '/cooking-minceur-old/activite',
    Quiz = '/cooking-minceur-old/quiz',
    Connaissez = '/cooking-minceur-old/connaissez',
    Equipement = '/cooking-minceur-old/equipement',
    Preparer = '/cooking-minceur-old/preparer',
    Poids = '/cooking-minceur-old/poids',
    LoaderForm = '/cooking-minceur/loading',
    Results = '/cooking-minceur/results',
}