import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import LandingPageContainerC from "./LandingPageContainerC";
import { LandingPagePathC } from ".";
import { CurrentUser } from "../../controllers/CurrentUser";


const LandingPageCp8 = () => {
    const [answer, setAnswer] = useState<number | undefined>(CurrentUser.answers.q9);
    const scrollRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();

    //window.scrollTo(0,800);
    useEffect(() => {
      setTimeout(() => {
        scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 500);
    }, []);
    
    const onSelected = (value: number) => {
      setAnswer(value);
      CurrentUser.answers.q11 = value;
    };

    const onContinue = () => {
      CurrentUser.answers.q11 = answer;
  
      setTimeout(() => {
        navigate(LandingPagePathC.Page9);
      }, 500);
    };
  
    return (
      <LandingPageContainerC>
        <div className="dpq-question" id="bcm-q8" data-progress="8" style={{display:"block"}}>
            <h5 className="text-center gray3 my-3" ref={scrollRef}>Le Meal Prep exige beaucoup d'équipements en cuisine, ce qui revient cher.</h5>
            <div className="quickquiz text-center">
                <label className={classNames({ selected: answer === 1 })}
                onClick={() => {
                  onSelected(1);
                }}>
                    <input type="radio" name="bcm-q8" value="1" onClick={() => {
                    onSelected(1);
                  }}/>
                    <strong>VRAI</strong>
                </label>
                <label className={classNames({ selected: answer === 0 })}
                onClick={() => {
                  onSelected(0);
                }}>
                    <input type="radio" name="bcm-q8" value="2" onClick={() => {
                    onSelected(0);
                  }}/>
                    <strong>FAUX</strong>
                </label>
            </div>
            {answer === 1 && (
            <div className="quizResult yes" style={{display:"block"}}>
                <h5 className="salmon"><strong>Pa si sûr...</strong></h5>
                <div>Les seuls équipements indispensables sont un four (même mini) et la possibilité de cuire les aliments dans une poêle ou une casserole. Ensuite, il vous faudra une quinzaine de boîtes hermétiques réutilisables (verre ou plastique) pour conserver vos plats au frigo ou au congélateur. L'investissement de départ est donc minime.</div>
            </div>
            )}
            {answer === 0 && (
            <div className="quizResult no" style={{display:"block"}}>
                <h5 className="salmon"><strong>Bravo, c'est faux en effet&nbsp;!</strong></h5>
                <div>Les seuls équipements indispensables sont un four (même mini) et la possibilité de cuire les aliments dans une poêle ou une casserole. Ensuite, il vous faudra une quinzaine de boîtes hermétiques réutilisables (verre ou plastique) pour conserver vos plats au frigo ou au congélateur. L'investissement de départ est donc minime.</div>
            </div>
            )}
            <br />
            {answer !== undefined && (  
            <div className="text-center continueQuiz">
                <button type="button" className="cta-salmon" onClick={onContinue}>Continuer</button>
            </div>
            )}
        </div>
      </LandingPageContainerC>
    );
  };
  
  export default LandingPageCp8;