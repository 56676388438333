import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import LandingPageContainerC from "./LandingPageContainerC";
import { LandingPagePathC } from ".";
import { CurrentUser } from "../../controllers/CurrentUser";


const LandingPageCp10 = () => {
    const [answer, setAnswer] = useState<number | undefined>(CurrentUser.answers.q10);
    const scrollRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();

    //window.scrollTo(0,800);
    useEffect(() => {
      setTimeout(() => {
        scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 500);
    }, []);
    
    const onSelected = (value: number) => {
      setAnswer(value);
      CurrentUser.answers.q10 = value;
    };

    const onContinue = () => {
      CurrentUser.answers.q10 = answer;
  
      setTimeout(() => {
        navigate(LandingPagePathC.Page11);
      }, 500);
    };
  
    return (
      <LandingPageContainerC>
        <div className="dpq-question" id="bcm-q10" data-progress="10" style={{display:"block"}}>
            <h5 className="text-center gray3 my-3" ref={scrollRef}>Souhaiteriez-vous voir à quoi ressemble un plan de repas-type de Meal Prep Minceur ?</h5>
            <div className="quickquiz text-center">
                <label className={classNames({ selected: answer === 1 })}
                onClick={() => {
                  onSelected(1);
                }}>
                    <input type="radio" name="bcm-q10" value="1" onClick={() => {
                    onSelected(1);
                    }}/>
                    <strong>OUI</strong>
                </label>

                <label className={classNames({ selected: answer === 0 })}
                onClick={() => {
                  onSelected(0);
                }}>
                    <input type="radio" name="bcm-q10" value="0" onClick={() => {
                    onSelected(0);
                  }}/>
                    <strong>NON</strong>
                </label>
            </div>

            {answer === 1 && (
            <div className="quizResult yes" style={{display:"block"}}>
                <div className="text-center">Très bien ! On vous le met de côté et on vous le donne à la fin du quiz&nbsp;🙂</div>
            </div>
            )}

            {answer === 0 && (
            <div className="quizResult no" style={{display:"block"}}>
                <div className="text-center">Dommage, ça aurait pu vous intéresser 🙂</div>
            </div>
            )}

            <br />
            {answer !== undefined && (  
            <div className="text-center continueQuiz">
                <button type="button" className="cta-salmon" onClick={onContinue}>Continuer</button>
            </div>
            )}
        </div>
      </LandingPageContainerC>
    );
  };
  
  export default LandingPageCp10;