import Cookies from "universal-cookie";
import { GenderType } from "../models/Types";
import DateUtil from "./DateUtil";

const cookies = new Cookies();

export default class CookieHelper {
  private static DietProfileId = "DietProfileId";
  private static DietProfileName = "CMFirstname";
  private static DietProfileEmail = "CMEmail";
  private static DietProfileWeight = "CMIWeight";
  private static DietProfileTargetWeight = "CMTWeight";
  private static DietProfileHeight = "CMHeight";
  private static DietProfileQuizDate = "CMQuizDate";
  private static DietProfileAge = "CMAge";
  private static DietProfileCalorieLevel = "CMCalorieLevel";
  private static TrackingSid = "sid";
  private static TrackingV= "v";
  private static ABLandingPage= "ABLp";

  public static setDietProfile(id: number, gender: GenderType): boolean {
    const options = {
      path: "/",
      secure: false,
      expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000), //7 days
    };

    cookies.set(CookieHelper.DietProfileId, id, options);
    var calorieLevel = 1600;
    if (gender == 1)
    {
      calorieLevel = 1800;
    }
    cookies.set(CookieHelper.DietProfileCalorieLevel, calorieLevel, options);
    cookies.set(CookieHelper.DietProfileQuizDate, DateUtil.format(new Date()), options);
    return true;
  }

  public static setDietProfileData(weight: number, targetWeight: number, height: number, age: number): boolean {
    const options = {
      path: "/",
      secure: false,
      expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000), //7 days
    };
    cookies.set(CookieHelper.DietProfileWeight, weight, options);
    cookies.set(CookieHelper.DietProfileTargetWeight, targetWeight, options);
    cookies.set(CookieHelper.DietProfileAge, age, options);
    cookies.set(CookieHelper.DietProfileHeight, height, options);
    return true;
  }

  public static setDietProfileLoaderData(firstname: string, email: string): boolean {
    const options = {
      path: "/",
      secure: false,
      expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000), //7 days
    };
    cookies.set(CookieHelper.DietProfileName, firstname, options);
    cookies.set(CookieHelper.DietProfileEmail, email, options);
    return true;
  }

  public static setTrackingSid(id: number): boolean {
    const options = {
      path: "/",
      secure: false,
      expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000), //7 days
    };
    cookies.set(CookieHelper.TrackingSid, id, options);
    return true;
  }

  public static setTrackingV(id: number): boolean {
    const options = {
      path: "/",
      secure: false,
      expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000), //7 days
    };
    cookies.set(CookieHelper.TrackingV, id, options);
    return true;
  }

  public static setABTestLandingPage(id: number): boolean {
    const options = {
      path: "/",
      secure: false,
      expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000), //7 days
    };
    cookies.set(CookieHelper.ABLandingPage, id, options);
    return true;
  }

  public static getABTestLandingPage(): string {
    const abTestLp = cookies.get(CookieHelper.ABLandingPage);
    
    return abTestLp;
  }
}
