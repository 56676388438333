import { useEffect } from "react";
import CommencerCard from "../shared/CommencerCard";
import VosQuestions from "../shared/VosQuestions";

const Faq = () => {
  useEffect(() => {
    document.title = 'FAQ - Meal Prep Minceur Dr Cohen';
  }, []);
  
  return (
    <section className="level2-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-8">
            <div className="bcm-leftcol-wrap">
              <h1 className="emerald">
                <strong>Vos questions sur le Meal Prep Minceur </strong>
              </h1>
              <p>
                <img src="images/bcm-content-faq.jpg" alt="FAQ" className="img-fluid" />
              </p>

              <VosQuestions hideTitle={true} />
            </div>
          </div>
          <div className="col-lg-4">
            <CommencerCard />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
