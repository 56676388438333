import { useNavigate } from "react-router-dom";
import LandingPageContainerC from "./LandingPageContainerC";
import { LandingPagePathC } from ".";
import { CurrentUser } from "../../controllers/CurrentUser";
import DietGraphSVG from "../dietprofile/DietGraphSVG";
import { useEffect, useRef } from "react";

const LandingPageGraphC = () => {
  const navigate = useNavigate();

  const scrollRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setTimeout(() => {
      scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 500);
  }, []);

  const onContinue = () => {
    setTimeout(() => {
      navigate(LandingPagePathC.LoaderForm);
    }, 500);
  };

  return (
    <LandingPageContainerC>
      <div className="dpq-wrapper">
          <div className="dpq-question" id="bcm-graph" data-progress="graph" style={{display:"block"}}><br />
          <h3 className="text-center salmon  my-3"><strong ref={scrollRef}>Ok, vous voulez perdre 10&nbsp;kilos </strong></h3>
          <h4 className="graphInfo">Sans chambouler vos habitudes... ni reprendre le poids perdu. </h4>
          <h4 className="graphInfo">Nous sommes justement là pour ça 😊</h4>
          {/* <div className="dpq-graph text-center">
            <img src="/dietprofile-images/bcm-dp-graph.jpg" alt="" className="img-fluid mx-auto" />
          </div> */}
          {/* <hr />
          <div className="dpq-graph text-center">
            <DietGraph
              initialWeight={CurrentUser.profile.initialWeight || 80}
              targetWeight={CurrentUser.profile.targetWeight || 70}
            />
          </div>
          <hr /> */}
          <div className="dpq-graph text-center">
            <DietGraphSVG
              initialWeight={CurrentUser.profile.weight || 80}
              targetWeight={CurrentUser.profile.targetWeight || 70}
            />
          </div>

          <p className="text-center">Voyons maintenant le meilleur moyen d’y arriver… et en combien de temps !</p>
          <div className="text-center">
            <button type="button" className="cta-salmon" onClick={onContinue}>
              Continuer
            </button>
          </div>
        </div>
      </div>
    </LandingPageContainerC>
  );
};

export default LandingPageGraphC;
