import { useNavigate } from "react-router-dom";
import LandingPageContainer from "./LandingPageContainer";
import { LandingPagePath } from ".";
import { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { CurrentUser } from "../../controllers/CurrentUser";

const LandingPageExces = () => {
  const [exces, setExces] = useState(CurrentUser.answers.q5 ?? 0);

  const navigate = useNavigate();

  const scrollRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setTimeout(() => {
      scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 500);
  }, []);

  const onSelect = (value: number) => {
    setExces(value);
    CurrentUser.answers.q5 = value;

    setTimeout(() => {
      navigate(LandingPagePath.Activity);
    }, 500);
  };

  return (
    <LandingPageContainer>
      <div className="dpq-wrapper">
        <div className="dpq-question d-block" data-progress="5">
          <h3 className="text-center  emerald">
            <strong >Quel type d’excès ou d’écarts faites-vous le plus souvent&nbsp;?</strong>
          </h3>

          <div className="multipleChoice" ref={scrollRef}>
            <label onClick={() => onSelect(1)} className={classNames({ selected: exces === 1 })}>
              Je grignote très souvent
            </label>

            <label onClick={() => onSelect(2)} className={classNames({ selected: exces === 2 })}>
              Je mange en trop grande quantité
            </label>

            <label onClick={() => onSelect(3)} className={classNames({ selected: exces === 3 })}>
              Je mange sans cesse à l’extérieur ou au restaurant
            </label>

            <label onClick={() => onSelect(4)} className={classNames({ selected: exces === 4 })}>
              J’adore les plats riches (en gras et/ou sucres)
            </label>

            <label onClick={() => onSelect(5)} className={classNames({ selected: exces === 5 })}>
              Non, rien de tout cela
            </label>
          </div>
        </div>
      </div>
    </LandingPageContainer>
  );
};

export default LandingPageExces;
