import { useEffect } from "react";
import CommencerCard from "../shared/CommencerCard";
import VosQuestions from "../shared/VosQuestions";

const CommentAdapter = () => {
  useEffect(() => {
    document.title = 'Comment adapter le Meal Prep Minceur pour toute la famille ? - Meal Prep Minceur Dr Cohen';
  }, []);
  
  return (
    <section className="level2-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-8">
            <div className="bcm-leftcol-wrap">
              <h1 className="emerald">
                <strong>Comment adapter le Meal Prep Minceur pour toute la famille&nbsp;?</strong>
              </h1>
              <p>
                <img src="images/bcm-content-comment-adapter.jpg" alt="maison" className="img-fluid" />
              </p>
              <p>
                Que vous soyez seul(e), trois ou encore cinq à la maison, le Meal Prep Minceur est{" "}
                <strong>tout à fait adapté</strong>&nbsp;! Il s’agit de cuisiner maison, des produits sains, en suivant
                des menus équilibrés et variés, de quoi donner de meilleures habitudes à toute{" "}
                <strong>votre famille !</strong> Pour adapter votre Meal Prep Minceur, je vous conseille de :
              </p>

              <ul>
                <li>
                  <strong>Déterminer quels repas seront pris solo ou partagés</strong>&nbsp;: certains déjeuners seront
                  juste pour vous à réchauffer sur le pouce, quand les dîners seront pris tous ensemble par exemple ;{" "}
                </li>
                <li>
                  <strong>Augmenter les quantités cuisinées, en fonction du nombre de convives,</strong> à l’arrivée,
                  cela ne vous prendra pas beaucoup plus de temps ;
                </li>
                <li>
                  <strong>Prévoir quelques assaisonnements à part</strong> pour que chacun puisse se servir selon ses
                  préférences. Vous pourrez également, ajuster les quantités de féculents et de pain selon leurs
                  besoins.
                </li>
                <li>
                  Avoir toujours quelques laitages nature ou aux fruits pour vous et d'autres desserts (crèmes, mousses,
                  entremets etc.) pour les autres membres de votre famille.{" "}
                </li>
              </ul>
              <p>
                Si le temps de préparation de votre Meal Prep Minceur devient trop long, en raison du nombre de
                personnes pour lesquelles vous cuisinez, je vous conseille de diviser votre session hebdomadaire en{" "}
                <strong>deux fois</strong> pour ne pas passer trop de temps en cuisine, votre mental sera déjà bien
                libéré les autres soirs de la semaine !
              </p>

              <br />
              <hr />
              <br />
              <VosQuestions />
            </div>
          </div>
          <div className="col-lg-4">
            <CommencerCard />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CommentAdapter;
