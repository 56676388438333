import { useEffect } from "react";
import CommencerCard from "../shared/CommencerCard";
import VosQuestions from "../shared/VosQuestions";

const ProduitsLaMaison = () => {
  useEffect(() => {
    document.title = 'Quels produits dois-je toujours avoir à la maison ? - Meal Prep Minceur Dr Cohen';
  }, []);
  
  return (
    <section className="level2-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-8">
            <div className="bcm-leftcol-wrap">
              <h1 className="emerald">
                <strong>Quels produits dois-je toujours avoir à la maison&nbsp;?</strong>
              </h1>
              <p>
                <img src="images/bcm-content-produits-maison.jpg" alt="produits maison" className="img-fluid" />
              </p>

              <p>
                Avec le Meal Prep Minceur, pour vous <strong>simplifier</strong> la préparation des repas, rester
                dans un <strong>budget convenable</strong> et conserver un <strong>équilibre nutritionnel</strong> dans
                l’assiette, je vous propose de vous constituer une liste d’essentiels de vos placards, ce sont les
                aliments que vous allez régulièrement utiliser comme ingrédients de base.
              </p>
              <p>
                D’abord des condiments à toujours avoir sous la main pour assaisonner vos plats, ainsi que des
                accompagnements (produits secs) pour ne jamais être au dépourvu(e).{" "}
              </p>
              <p>Voici une liste de produits malins et utiles&nbsp;:</p>

              <h4>
                <strong>Epices</strong>
              </h4>
              <ul>
                <li>
                  <strong>Sel fin, fleur de sel, gros sel.</strong>
                </li>
                <li>
                  <strong>Poivre noir</strong> moulu ou en moulin, mélange de poivres et de baies.{" "}
                </li>
                <li>Les indispensables : muscade, curry, coriandre, curcuma, cannelle.</li>
                <li>Les optionnels : cumin, piment doux, graines de moutarde, gingembre.</li>
                <li>Herbes de Provence, thym et laurier.</li>
                <li>Ail, oignons échalotes.</li>
                <li>
                  <strong>
                    Les aides culinaires (fond de volaille, fond de veau, cubes de bouillon de bœuf, volaille ou
                    légumes, dégraissés)
                  </strong>
                  &nbsp;: en cube, en poudre.
                </li>
              </ul>

              <h4>
                <strong>Condiments</strong>
              </h4>
              <ul>
                <li>La pulpe de tomate, le coulis de tomate et le concentré de tomates.</li>
                <li>La moutarde. </li>
                <li>Le ketchup.</li>
                <li>La sauce soja.</li>
                <li>Les cornichons, câpres, pickles.</li>
                <li>
                  Le <strong>Jus de citron sans sucre ajouté</strong>.
                </li>
              </ul>

              <h4>
                <strong>Céréales et légumineuses</strong>
              </h4>
              <ul>
                <li>Riz : basmati, long…</li>
                <li>Semoule de couscous.</li>
                <li>
                  <strong>Pâtes</strong> de toutes formes classiques ou au blé complet.{" "}
                </li>
                <li>
                  Les légumineuses : lentilles vertes ou corail, pois cassés, haricots secs, pois chiches,
                  flageolets,... secs ou en conserve.{" "}
                </li>
                <li>Quinoa, boulgour, blé.</li>
                <li>Biscottes au blé complet.</li>
                <li>
                  Céréales de blé complet ou de maïs nature non sucré, muesli floconneux nature sans fruits, flocons
                  d'avoine.
                </li>
              </ul>

              <h4>
                <strong>Huiles et vinaigres</strong>
              </h4>
              <ul>
                <li>
                  <strong>Huile d'olive</strong>, pour la cuisson ou l'assaisonnement.
                </li>
                <li>
                  <strong>Huile de colza ou noix</strong>, pour l'assaisonnement.
                </li>
                <li>
                  <strong>Vinaigre de vin, cidre, noix, balsamique, blanc, xérès, framboise</strong>&nbsp;: à varier
                  selon vos goûts.
                </li>
              </ul>

              <h4>
                <strong>Ingrédients de base</strong>
              </h4>
              <ul>
                <li>Lait (demi-écrémé).</li>
                <li>Farine.</li>
                <li>Fécule de maïs ou de pomme de terre.</li>
                <li>Sucre, miel, confiture.</li>
                <li>Levure chimique ou de boulanger déshydratée.</li>
                <li>Extrait de vanille.</li>
              </ul>

              <br />
              <hr />
              <br />
              <VosQuestions />
            </div>
          </div>
          <div className="col-lg-4">
            <CommencerCard />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProduitsLaMaison;
