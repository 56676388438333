import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input } from "reactstrap";
import { LandingPagePathC } from ".";
import { CurrentUser } from "../../controllers/CurrentUser";
import StringUtil from "../../utils/StringUtil";
import LandingPageContainerC from "./LandingPageContainerC";
import CookieHelper from "../../utils/CookieHelper";
import styled from "styled-components";

interface InValidState {
  message?: string;
}

interface InValidFormState {
  currentWeight?: InValidState;
  targetWeight?: InValidState;
  height?: InValidState;
  age?: InValidState;
}

const initialFormState: InValidFormState = {};

const InputValueDisplay = (props: { value: string; suffix: string }) => {
  return (
    <>
      <span className={classNames({ "input-value-display": true, "text-dark": StringUtil.ToNumeric(props.value) > 0 })}>{props.value}</span>{" "}
      <span className="input-after-text">{props.suffix}</span>
    </>
  );
};

const LandingPageUserDetailsC = () => {
  const [currentWeight, setCurrentWeight] = useState(CurrentUser.profile.weight?.toString() || "0");
  const [heightStr, setHeightStr] = useState(CurrentUser.profile.height?.toString() || "0");
  const [ageStr, setAgeStr] = useState(CurrentUser.profile.age?.toString() || "0");
  const [targetWeightStr, setTargetWeightStr] = useState(CurrentUser.profile.targetWeight?.toString() || "0");
  const [invalidState, setInvalidState] = useState<InValidFormState>(initialFormState);

  const navigate = useNavigate();

  const scrollRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setTimeout(() => {
      scrollRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 500);
  }, []);

  const saveChanges = async () => {
    let _state = initialFormState;

    const weight = StringUtil.ToNumeric(currentWeight);
    const height = StringUtil.ToNumeric(heightStr);
    const age = StringUtil.ToNumeric(ageStr);
    const targetWeight = StringUtil.ToNumeric(targetWeightStr);

    if (!weight || weight < 40 || weight > 200) {
      _state = {
        ..._state,
        currentWeight: {
          message:
            "Merci de bien vérifier le poids que vous avez indiqué. Nous ne pouvons conseiller sur internet que les personnes dont le poids est compris entre 40 et 200 kg. Uniquement les valeurs numériques sont acceptées. Ne pas mettre de virgule, de point ou de lettre.",
        },
      };
    } else if (weight / ((height * height) / 10000) < 19 && height && height > 140 && height < 220) {
      _state = {
        ..._state,
        currentWeight: {
          message:
            "Attention, votre poids actuel est insuffisant par rapport à votre taille. Ce site est destiné\naux personnes en surpoids, nous vous encourageons donc à consulter votre médecin traitant.",
        },
      };
    }

    if (!height || height < 140 || height > 220) {
      _state = { ..._state, height: { message: "Merci de préciser votre taille" } };
    }

    if (!ageStr) {
      _state = { ..._state, age: { message: "Merci de préciser votre ans" } };
    } else if (age < 18) {
      _state = { ..._state, age: { message: "Vous devez avoir au moins 18 ans pour suivre ce programme." } };
    }

    if (!targetWeight || targetWeight < 40 || targetWeight > 200) {
      _state = {
        ..._state,
        targetWeight: {
          message:
            "Merci de bien vérifier le poids que vous avez indiqué. Nous ne pouvons conseiller sur internet que les personnes dont le poids est compris entre 40 et 200 kg. Uniquement les valeurs numériques sont acceptées. Ne pas mettre de virgule, de point ou de lettre.",
        },
      };
    } else if (targetWeight < (19 * height * height) / 10000 && height && height > 140 && height < 220) {
      _state = {
        ..._state,
        targetWeight: {
          message: `Attention, le poids que vous visez est inférieur au poids santé recommandé pour votre taille sur le plan médical, c'est à dire  ${
            (19 * height * height) / 10000
          } kg.\nIl vous est donc vivement recommandé de revoir votre objectif de poids ou de consulter votre médecin traitant.`,
        },
      };
    } else if (targetWeight >= weight) {
      _state = {
        ..._state,
        targetWeight: {
          message: "e poids que vous souhaitez peser n'est pas inférieur à votre poids actuel. Merci de modifier votre objectif.",
        },
      };
    }

    setInvalidState(_state);
    if (_state !== initialFormState) {
      return;
    }

    CurrentUser.profile.weight = weight;
    CurrentUser.profile.height = height;
    CurrentUser.profile.age = age;
    CurrentUser.profile.targetWeight = targetWeight;

    CookieHelper.setDietProfileData(weight, targetWeight, height, age);

    setTimeout(() => {
      navigate(LandingPagePathC.DietGraph);
    }, 1000);
  };

  return (
    <LandingPageContainerC>
      <div className="dpq-wrapper">
          <div className="dpq-question" id="bcm-q12" data-progress="12" style={{display:"block"}}>
          <h3 className="text-center  gray3  my-3"><strong ref={scrollRef}>Voyons ensemble où vous en&nbsp;êtes&nbsp;:</strong></h3>
          <div className="dpq-profileForm">
            <div className="form-group">
              <label htmlFor="currentWeight">Aujourd’hui, vous pesez&nbsp;:</label>
              <div className="input-after">
                <Input
                  type="number"
                  id="currentWeight"
                  maxLength={4}

                  onClick={(e) => {
                    if (e.currentTarget.value === "0")
                    {
                      e.currentTarget.value = "";
                    }
                  }}

                  onChange={(e) => {
                    if (StringUtil.isNumeric(e.currentTarget.value)) {
                      setCurrentWeight(e.currentTarget.value);
                    }
                  }}
                  defaultValue={currentWeight}
                />
                <InputAfterContent className="input-after-content" onClick={() => document.getElementById("currentWeight")?.focus()}>
                  <InputValueDisplay value={currentWeight} suffix="kg" />
                </InputAfterContent>
              </div>
              {invalidState.currentWeight && <div className="bcmdp-errorMsg ">{invalidState.currentWeight.message}</div>}
            </div>

            <div className="mt-3" />
            <div className="form-group">
              <label htmlFor="height">Pour une taille de&nbsp;:</label>
              <div className="input-after">
                <Input
                  type="number"
                  id="height"

                  onClick={(e) => {
                    if (e.currentTarget.value === "0")
                    {
                      e.currentTarget.value = "";
                    }
                  }}

                  onChange={(e) => {
                    if (StringUtil.isNumeric(e.currentTarget.value)) {
                      setHeightStr(e.currentTarget.value);
                    }
                  }}
                  defaultValue={heightStr}
                />
                <InputAfterContent className="input-after-content" onClick={() => document.getElementById("height")?.focus()}>
                  <InputValueDisplay value={heightStr} suffix="cm" />
                </InputAfterContent>
              </div>
              {invalidState.height && <div className="bcmdp-errorMsg ">{invalidState.height.message}</div>}
            </div>

            <div className="mt-3" />
            <div className="form-group">
              <label htmlFor="age">Vous avez&nbsp;:</label>

              <div className="input-after">
                <Input
                  type="number"
                  id="age"

                  onClick={(e) => {
                    if (e.currentTarget.value === "0")
                    {
                      e.currentTarget.value = "";
                    }
                  }}

                  onChange={(e) => {
                    if (StringUtil.isNumeric(e.currentTarget.value)) {
                      setAgeStr(e.currentTarget.value);
                    }
                  }}
                  defaultValue={ageStr}
                />
                <InputAfterContent className="input-after-content" onClick={() => document.getElementById("age")?.focus()}>
                  <InputValueDisplay value={ageStr} suffix="ans" />
                </InputAfterContent>
              </div>
              {invalidState.age && <div className="bcmdp-errorMsg ">{invalidState.age.message}</div>}
            </div>

            <div className="mt-3" />
            <div className="form-group">
              <label htmlFor="targetWeight">Et vous voulez peser&nbsp;:</label>

              <div className="input-after">
                <Input
                  type="number"
                  id="targetWeight"

                  onClick={(e) => {
                    if (e.currentTarget.value === "0")
                    {
                      e.currentTarget.value = "";
                    }
                  }}

                  onChange={(e) => {
                    if (StringUtil.isNumeric(e.currentTarget.value)) {
                      setTargetWeightStr(e.currentTarget.value);
                    }
                  }}
                  defaultValue={targetWeightStr}
                />
                <InputAfterContent className="input-after-content" onClick={() => document.getElementById("targetWeight")?.focus()}>
                  <InputValueDisplay value={targetWeightStr} suffix="kg" />
                </InputAfterContent>
              </div>
              {invalidState.targetWeight && <div className="bcmdp-errorMsg ">{invalidState.targetWeight.message}</div>}
            </div>
            <div className="mt-3" />
          </div>

          <div className="text-center">
            <button type="button" className="cta-salmon" onClick={saveChanges}>
              Continuer
            </button>
          </div>
        </div>
      </div>
    </LandingPageContainerC>
  );
};

export default LandingPageUserDetailsC;

const InputAfterContent = styled.div`
  span {
    user-select: none;
    cursor: text;
  }
`;
