import { configureStore } from '@reduxjs/toolkit';

import loadingReducer from './features/loading';
//import dietProfileReducer from './features/dietProfile';

export const store = configureStore({
    reducer: {
        loading: loadingReducer,
       // dietProfile: dietProfileReducer,
    },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
