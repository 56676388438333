import { useNavigate } from "react-router-dom";
import LandingPageContainerB from "./LandingPageContainerB";
import { LandingPagePathB } from ".";
import { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { CurrentUser } from "../../controllers/CurrentUser";

const LandingPageActivityB = () => {
  const [activity, setActivity] = useState(CurrentUser.answers.q6 ?? 0);

  const navigate = useNavigate();
  const scrollRef = useRef<HTMLDivElement>(null);
  
  useEffect(() => {
    setTimeout(() => {
      scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 500);
  }, []);

  const onSelect = (value: number) => {
    setActivity(value);
    CurrentUser.answers.q6 = value;

    setTimeout(() => {
      navigate(LandingPagePathB.Quiz);
    }, 500);
  };

  return (
    <LandingPageContainerB>
      <div className="dpq-wrapper">
        <div className="dpq-question d-block" data-progress="6">
          <h3 className="text-center  emerald">
            <strong ref={scrollRef}>Quel est votre niveau d’activité physique ?</strong>
          </h3>

          <div className="multipleChoice">
            <label onClick={() => onSelect(1)} className={classNames({ selected: activity === 1 })}>
              Je bouge très peu, que ce soit au travail ou à la maison
            </label>

            <label onClick={() => onSelect(2)} className={classNames({ selected: activity === 2 })}>
              Je marche juste un peu au quotidien
            </label>

            <label onClick={() => onSelect(3)} className={classNames({ selected: activity === 3 })}>
              Je fais des activités de type promenade, jardinage etc.
            </label>

            <label onClick={() => onSelect(4)} className={classNames({ selected: activity === 4 })}>
              Je fais du sport au moins 1 fois par semaine
            </label>
          </div>
        </div>
      </div>
    </LandingPageContainerB>
  );
};

export default LandingPageActivityB;
