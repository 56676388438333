import { createSlice } from '@reduxjs/toolkit';

interface ILoading {
    show: boolean;
    style: 'Modal' | 'Background';
}

const initialState: ILoading = {
    show: false,
    style: 'Modal',
};

export const loadingSlice = createSlice({
    name: 'loading',
    initialState,
    reducers: {
        showLoadingModal: (state, action) => {
            state.show = action.payload;
            state.style = 'Modal';
        },
    },
});

export const { showLoadingModal } = loadingSlice.actions;
export default loadingSlice.reducer;
