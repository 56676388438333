import classNames from "classnames";
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { LandingPagePath } from ".";
import { CurrentUser } from "../../controllers/CurrentUser";
import { GenderType } from "../../models/Types";
import LandingPageContainer from "./LandingPageContainer";
import { useSearchParams } from 'react-router-dom';
import StringUtil from "../../utils/StringUtil";
import Cookies from "universal-cookie";

const LandingPageGender = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dpVersion = 1;

  var trackingSid = searchParams.get('sid');
  var trackingV = searchParams.get('v');
  var cookies = new Cookies();
  
  if (!trackingSid)
  {
    trackingSid = cookies.get('sid');
  }

  if (!trackingV)
  {
    trackingV = cookies.get('v');
  }

  const abTestLp = cookies.get('ABLp');
  
  const [gender, setGender] = useState<GenderType | undefined>(undefined);

  const onSelected = async (value: GenderType | undefined) => {
    setGender(value);
    if (trackingSid && StringUtil.isNumeric(trackingSid))
    {
      CurrentUser.profile.trackingSid = parseInt(trackingSid);
    }
  
    if (trackingV && StringUtil.isNumeric(trackingV))
    {
      CurrentUser.profile.trackingV = parseInt(trackingV);
    }
    
    CurrentUser.profile.dpVersion = dpVersion;
    CurrentUser.profile.gender = value;
    CurrentUser.answers.q1 = value;
    await CurrentUser.addProfile();

    setTimeout(() => {
      navigate(LandingPagePath.SelectIdeal);
    }, 500);
  };

  React.useEffect(() => {
    if (!CurrentUser.profile.abLandingPage)
    {
      CurrentUser.setABVersion(1);
    }
    
    /*
    if (CurrentUser.profile.abLandingPage == "cooking-minceur-b")
    {
      setTimeout(() => {
        navigate('/cooking-minceur-b/');
      }, 100);
    }
    */
    CurrentUser.clearProfile();
    CurrentUser.clearAnswers();
  }, []);

  return (
    <LandingPageContainer>
      <div className="dpq-question" id="bcm-q1" data-progress="1" style={{display:"block"}}>
          <h3 className="text-center emerald"><strong>Vous êtes&nbsp;:</strong></h3>
          <div className="genderSelect text-center">
              <label htmlFor="q1-1" className={classNames({ selected: gender === 0 })}>
                  <span className="d-block text-center pb-3">
                      <img src="/dietprofile-images/mealprep-landing-female.png" alt="Femme" className="img-fluid"/>
                  </span>

                  <input type="radio" name="bcm-gender" value="0" id="q1-1" onClick={() => {
                onSelected(0);
              }}/>
                  Une Femme
              </label>

              <label htmlFor="q1-2" className={classNames({ selected: gender === 1 })}>

                  <span className="d-block text-center  pb-3">
                      <img src="/dietprofile-images/mealprep-landing-male.png" alt="Homme" className="img-fluid"/>
                  </span>
                  <input type="radio" name="bcm-gender" value="1" id="q1-2" onClick={() => {
                onSelected(1);
              }}/>
                  Un Homme
              </label>

          </div>
      </div>
    </LandingPageContainer>
  );
};

export default LandingPageGender;
