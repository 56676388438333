import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Progress } from "reactstrap";
import { DietProfilePath } from ".";
import DietProfileHeaderNoLinks from "./DietProfileHeaderNoLinks";
import BrowserUtil from "../../utils/BrowserUtil";

const DietProfileRoot = () => {
  const [progress, setProgress] = useState(0);

  const { pathname } = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    switch (pathname.toLowerCase()) {
      case DietProfilePath.SelectIdeal:
        setProgress(2);
        break;
      case DietProfilePath.UserDetails:
        setProgress(3);
        break;
      case DietProfilePath.Figure:
        setProgress(4);
        break;
      case DietProfilePath.Exces:
        setProgress(5);
        break;
      case DietProfilePath.Activity:
        setProgress(6);
        break;
      case DietProfilePath.Connaissez:
        setProgress(7);
        break;
      case DietProfilePath.Equipement:
        setProgress(8);
        break;
      case DietProfilePath.Preparer:
        setProgress(9);
        break;
      case DietProfilePath.Poids:
        setProgress(10);
        break;
      default:
        setProgress(0);
        break;
    }
  }, [pathname]);

  return (
    <>
      {!BrowserUtil.isMobileApp() && <DietProfileHeaderNoLinks />}

      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              {progress > 0 && (
                <div className="dpq-wrapper">
                  <div className="dpq-progress">
                    <div className="dpq-progress-nav">
                      <div className="progress-retour">
                        <i className="fal fa-chevron-left"></i>&nbsp;{" "}
                        <a
                          href="#non"
                          onClick={(e) => {
                            e.preventDefault();
                            navigate(-1);
                          }}
                        >
                          Étape précédente
                        </a>
                      </div>
                      <div className="progress-qcount">{progress}/10</div>
                    </div>
                    <Progress value={progress * 10} />
                    <br />
                  </div>
                </div>
              )}
              <Outlet />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DietProfileRoot;
