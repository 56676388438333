import React, { useEffect } from "react";
import CommencerCard from "../shared/CommencerCard";
import VosQuestions from "../shared/VosQuestions";

const AlimentsRechauffer = () => {
  useEffect(() => {
    document.title = 'Quelques notions d’hygiène - Meal Prep Minceur Dr Cohen';
  }, []);

  return (
    <section className="level2-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-8">
            <div className="bcm-leftcol-wrap">
              <h1 className="emerald">
                <strong>Quelques notions d’hygiène</strong>
              </h1>
              <p>
                <img src="images/bcm-content-aliments-rechauffer.jpg" alt="aliments rechauffer" className="img-fluid" />
              </p>

              <p>
                Si vous avez été méticuleux(se) à l’achat (DLC suffisamment longue par rapport au moment de
                consommation, dans le transport et le stockage de vos courses (sac isotherme, respect de la chaîne du
                froid), puis dans la préparation et la cuisson, <strong>tous vos plats pourront être réchauffés</strong>{" "}
                au four à micro-ondes ou four traditionnel. Réchauffer suffisamment correspond en réglementation à
                atteindre 63°C à cœur de l’aliment soit au four, soit au micro-ondes au moins 3 minutes.
              </p>
              <p>N’oubliez pas que beaucoup de contaminations ont lieu à la maison ! Soyez bien rigoureux(se) sur :</p>
              <ul>
                <li>
                  <strong>Le stockage</strong>&nbsp;: au retour des courses, rangez en premier les surgelés, puis
                  produits frais puis le sec. Il est conseillé d'éliminer les sur-emballages dès que possible.
                </li>
                <li>
                  <strong>Le lavage de vos mains</strong> qui doit être régulier et impératif après un passage aux
                  toilettes, vous être mouché(e), avoir touché la poubelle ou simplement l’éponge pour nettoyer le plan
                  de travail et lorsque vous changez de denrées manipulées (entre les légumes et la viande par exemple).
                </li>
                <li>
                  Le lavage régulier des <strong>plans de travail</strong> avec élimination des déchets.
                </li>
                <li>
                  Le changement ou lavage des <strong>ustensiles</strong> entre les différentes classes d’aliments. Par
                  exemple changer ou laver le couteau et la planche à découper entre les légumes et la viande ou le
                  poisson.{" "}
                </li>
                <li>
                  Si vous goûtez une préparation, utilisez une <strong>petite cuillère propre</strong> qui sera
                  immédiatement lavée
                </li>
                <li>
                  Surveillez régulièrement la <strong>température de votre réfrigérateur</strong> qui ne doit pas
                  excéder 6°C dans la zone centrale et pensez à le nettoyer à l’eau légèrement javellisée ou additionnée
                  de vinaigre d’alcool au moins 1 fois par mois. Nettoyez immédiatement tout déchet visible.
                </li>
              </ul>
              <p>
                Pensez à être attentif(ve) aux signes d'altération lorsque vous sortez une préparation du réfrigérateur,
                en surveillant les quelques <strong>signes d'altération</strong>&nbsp;:
              </p>
              <ul>
                <li>couvercle ou film bombé/gonflé,</li>
                <li>odeur désagréable,</li>
                <li>changement de couleur important (ex : blanchiement des légumes cuits),</li>
                <li>saveur désagréable.</li>
              </ul>

              <br />
              <hr />
              <br />
              <VosQuestions />
            </div>
          </div>
          <div className="col-lg-4">
            <CommencerCard />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AlimentsRechauffer;
